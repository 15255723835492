const {
    ORDER_LOADED_FAILURE,
    CUSTOMIZATION_INFO_SUCCESS,
    ORDER_LOADED_SUCCESS,
    END_PROCESS_SUCCESS,
    CUSTOMIZED_ITEM_SUCCESS,
    RESET_OPERATION_RESULT,
    SET_OPERATION_RESULT,
    SET_OPERATION_SOUNDS,
    SET_SERVER_ERROR,
    REMOVE_CUSTOMIZATION_SUCCESS
} = require('../mutation-types.js');
const { DONE_STATUS, UNDONE_STATUS } = require('../../constants.js');

const Orders = require('../../models/Orders').default;

import auth from '../../auth';
import { isLoginCode } from '../../helpers';

const state = {
    rows: [],
};

const getters = {
    itemsToCustomize: state => _.filter(state.rows, { check: 'undone' }),
    itemsCustomized: state => _.filter(state.rows, { check: 'done' }),
};

const actions = {
    async loadCustomizeOrder({ commit, getters }) {
        let orderId = getters.orderId;

        try {
            let { data } = await Orders.loadCustomizeOrder(orderId);
            let { rows } = data;

            if (rows === undefined) {
                let { response, description } = data;
                commit(ORDER_LOADED_FAILURE, { type: response, message: description });
            } else {
                commit(CUSTOMIZATION_INFO_SUCCESS, rows);
                commit(ORDER_LOADED_SUCCESS);
            }
        } catch (error) {
            commit(ORDER_LOADED_FAILURE, error.response.data);
        }
    },

    checkCustomization({ commit, getters, dispatch }, method = 'scanner') {
        return new Promise((resolve, reject) => {
            let command = getters.currentCommand;
            let orderId = getters.orderId;

            let data = {
                source: 'CUSTOMIZATION',
                command,
                orderId,
                method
            };

            if (isLoginCode(command)) {
                auth.fastLogin(command, loginSuccess => {
                    if (loginSuccess) {
                        dispatch('loadUser');
                        resolve();
                    } else {
                        auth.logout(() => next({ path: '/login' }));
                    }
                });
            } else {
                Orders.scan(data)
                    .then(resp => {
                        let { response, operation, sounds, description } = resp.data;

                        if (response === 'OK') {
                            switch (operation) {
                                case 'ENDPROCESS':
                                    commit(END_PROCESS_SUCCESS);
                                    break;
                                case 'CHECKED_ITEM':
                                    commit(CUSTOMIZED_ITEM_SUCCESS, parseInt(command));
                                    break;
                            }

                            commit(RESET_OPERATION_RESULT);
                        } else if (response === 'KO') {

                            commit(SET_OPERATION_RESULT, description);
                        }

                        commit(SET_OPERATION_RESULT, { description, level: response });

                        commit(SET_OPERATION_SOUNDS, { sounds });

                        resolve();
                    }).catch(error => {
                    commit(SET_SERVER_ERROR, error);
                    reject();
                });
            }
        })
    },

    removeCustomization({ commit }, fulfilment_order_product_id) {
        return new Promise((resolve, reject) => {
            Orders.removeCustomization(fulfilment_order_product_id)
                .then(response => {
                    commit(REMOVE_CUSTOMIZATION_SUCCESS, fulfilment_order_product_id);
                    commit(RESET_OPERATION_RESULT);
                })
        })
    }
};

const mutations = {
    [CUSTOMIZATION_INFO_SUCCESS](state, rows) {
        state.rows = rows;
    },

    [CUSTOMIZED_ITEM_SUCCESS](state, fulfilment_order_product_id) {
        let customizedRow = _.find(state.rows, { fulfilment_order_product_id });

        customizedRow.check = DONE_STATUS;
    },

    [REMOVE_CUSTOMIZATION_SUCCESS](state, fulfilment_order_product_id) {
        let customizedRow = _.find(state.rows, { fulfilment_order_product_id });

        customizedRow.check = UNDONE_STATUS;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}