const {
    LOAD_ORDER_NOTES_SUCCESS,
    SET_SERVER_ERROR,
    NOTIFICATION_READ_SUCCESS,
    UPDATE_SAW_PHOTOS
} = require('../mutation-types.js');
const Orders = require('../../models/Orders').default;
import _ from 'lodash';

const state = {
    list: []
};

const actions = {
    async loadOrderNotes({ commit, getters }) {
        let orderId = getters.orderId;
        let { data } = await Orders.loadNotes(orderId);

        commit(LOAD_ORDER_NOTES_SUCCESS, data);
    },

    insertNote({ commit, getters }, body) {
        return new Promise((resolve, reject) => {
            let orderId = getters.orderId;

            Orders.insertNote(orderId, body)
                .then(resp => {
                    let { data } = resp;
                    commit(LOAD_ORDER_NOTES_SUCCESS, data);

                    resolve();
                }).catch(error => {

                commit(SET_SERVER_ERROR, error);
                reject(error);
            });
        })

    },

    async confirmReadNote({ commit }, noteId) {
        try {
            let { data } = await Orders.confirmReadNote(noteId);
            commit(NOTIFICATION_READ_SUCCESS, { noteId: data.id, readBy: data.read_by, readAt: data.read_at });
        } catch (error) {
            commit(SET_SERVER_ERROR, error)
        }
    },
};

const mutations = {
    [LOAD_ORDER_NOTES_SUCCESS](state, notes) {
        notes.forEach(note => {
            let showConfirm = true;
            if (!note.read_by && note.pictures.length > 0) {
                showConfirm = false;
            }

            note.showConfirm = showConfirm;
        });

        state.list = notes;
    },

    [NOTIFICATION_READ_SUCCESS](state, { noteId, readBy, readAt }) {
        let note = _.find(state.list, { id: noteId });
        note['read_by'] = readBy;
        note['read_at'] = readAt;
    },

    [UPDATE_SAW_PHOTOS](state, noteId) {
        let note = _.find(state.list, { id: noteId });
        note['showConfirm'] = true;
    }
};

export default {
    state,
    actions,
    mutations
}
