const {
    ORDER_LOADED_FAILURE,
    ORDER_LOADED_SUCCESS,
    RESET_OPERATION_RESULT,
    START_SCAN,
    END_PROCESS_SUCCESS,
    RESET_QUANTITY,
    SET_OPERATION_SOUNDS,
    SET_OPERATION_RESULT,
    END_SCAN,
    RESET_CHECK_ORDER,
    SET_ORDER,
    DISABLE_FOCUS,
    RESET_COMMAND,
    ENABLE_FOCUS,
    SET_COMMAND,
    SET_QUANTITY,
    RESET_OPERATION,
    CLOSE_OPERATION_DETAIL,
    START_DELETE,
    END_DELETE,
    CHANGE_BOX_START,
    CHANGE_BOX_END,
    SET_CTW
} = require('../mutation-types.js');

const state = {
    orderId: undefined,
    loading: false,
    error: {
        type: null,
        message: null,
    },
    command: null,
    quantity: 1,
    result: null,
    level: null,
    sounds: [],
    completed: false,
    scanInProgress: false,
    deleteInProgress: false,
    changeBoxOn: false,
    scanFocus: true,
    ctw: false,
};

const getters = {
    orderIsLoading: state => state.loading,
    orderId: state => state.orderId,
    orderErrorType: state => state.error.type,
    orderErrorMessage: state => state.error.message,
    currentCommand: state => state.command,
    currentQuantity: state => state.quantity,
    operationResult: state => state.result,
    operationLevel: state => state.level,
    operationSounds: state => state.sounds,
    operationCompleted: state => state.completed,
    scanInProgress: state => state.scanInProgress,
    deleteInProgress: state => state.deleteInProgress,
    changingBox: state => state.changeBoxOn,
    scanFocus: state => state.scanFocus,
    closingToWrap: state => state.ctw,
};

const actions = {
    setOrder({ commit }, orderId) {
        commit(SET_ORDER, orderId);
    },

    resetCommand({ commit }) {
        commit(DISABLE_FOCUS);
        commit(RESET_COMMAND);
        commit(ENABLE_FOCUS);
    },

    setCommand({ commit }, command) {
        commit(SET_COMMAND, command);
    },

    setQuantity({ commit }, quantity) {
        commit(SET_QUANTITY, quantity);
    },

    resetOperation({ commit }) {
        commit(RESET_OPERATION);
    },

    closeOperationDetail({ commit }) {
        commit(CLOSE_OPERATION_DETAIL);
        commit(RESET_CHECK_ORDER);
    },

    startScan({ commit }) {
        commit(START_SCAN);
    },

    endScan({ commit }) {
        commit(END_SCAN);
    },

    startDelete({ commit }) {
        commit(START_DELETE);
    },

    endDelete({ commit }) {
        commit(END_DELETE);
    },

    startChangeBox({ commit }) {
        commit(CHANGE_BOX_START);
    },

    endChangeBox({ commit }) {
        commit(CHANGE_BOX_END);
    },

    enableFocus({ commit }) {
        commit(ENABLE_FOCUS);
    },

    disableFocus({ commit }) {
        commit(DISABLE_FOCUS);
    },

    setCTW({ commit }, check) {
        commit(SET_CTW, check);
    }

};

const mutations = {
    [SET_COMMAND](state, command) {
        state.command = command;
    },

    [SET_QUANTITY](state, quantity) {
        state.quantity = quantity;
    },

    [RESET_COMMAND](state) {
        state.command = null;
    },

    [RESET_OPERATION](state) {
        state.orderId = undefined;
        state.loading = false;
        state.error = { type: null, message: null };
        state.result = null;
        state.completed = false;
        state.sounds = [];
        state.ctw = false;
    },

    [SET_OPERATION_RESULT](state, { description, level }) {
        state.result = description;
        state.level = level;
    },

    [RESET_OPERATION_RESULT](state) {
        state.result = null;
        state.level = null;
        state.sounds = [];
    },

    [SET_ORDER](state, orderId) {
        state.orderId = orderId;
        state.loading = true;
        state.error = { type: null, message: null };
        state.result = null;
        state.completed = false;
    },

    [ORDER_LOADED_SUCCESS](state) {
        state.loading = false;
        state.error = { type: null, message: null };
        state.result = null;
    },

    [ORDER_LOADED_FAILURE](state, { type, message }) {
        state.loading = false;
        state.error = { type, message };
        state.result = null;
    },

    [CLOSE_OPERATION_DETAIL](state) {
        state.showDetails = false;
        state.order = '';
        state.sounds = [];
    },

    [SET_OPERATION_SOUNDS](state, { sounds }) {
        state.sounds = sounds;
    },

    [END_PROCESS_SUCCESS](state) {
        state.completed = true;
        state.sounds = [];
    },

    [START_SCAN](state) {
        state.scanInProgress = true;
    },

    [END_SCAN](state) {
        state.scanInProgress = false;
    },

    [START_DELETE](state) {
        state.deleteInProgress = true;
    },

    [END_DELETE](state) {
        state.deleteInProgress = false;
    },

    [CHANGE_BOX_START](state) {
        state.changeBoxOn = true;
    },

    [CHANGE_BOX_END](state) {
        state.changeBoxOn = false;
    },

    [ENABLE_FOCUS](state) {
        state.scanFocus = true;
    },

    [DISABLE_FOCUS](state) {
        state.scanFocus = false;
    },

    [RESET_QUANTITY](state) {
        state.quantity = 1;
    },

    [SET_CTW](state, check) {
        state.ctw = check;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}

