<template>
    <form @submit.prevent="login">
        <div class="box">
            <label for="email" class="label">Email</label>
            <p class="control">
                <input id="email" v-model="email" class="input" type="email" placeholder="email" required>
            </p>
            <label for="password" class="label">Password</label>
            <p class="control">
                <input id="password" v-model="password" class="input" type="password" placeholder="●●●●●●●" required>
            </p>
            <label v-if="loginError" class="login-error">Login errato</label>
            <hr>
            <p class="control">
                <button class="button is-primary" :disabled="loggingIn">Entra</button>
            </p>
        </div>
    </form>
</template>

<script>
import auth from '../../auth';
import {getCookie} from "../../router";

export default {
    data() {
        return {
            email: '',
            password: '',
            loginError: false,
            loggingIn: false,
        }
    },
    methods: {
        login() {
            this.loggingIn = true;
            auth.login(this.email, this.password, loggedIn => {
                if (loggedIn) {
                    this.$store.dispatch('loadUser');
                    let lastTab = getCookie('path');
                    if (lastTab) this.$router.push(lastTab);
                    else this.$router.replace(this.$route.query.redirect || '/plan');
                } else {
                    this.loginError = true;
                    this.loggingIn = false;
                }
            });
        }
    },
}
</script>
