<template>
    <div class="control is-horizontal">
        <div class="control is-grouped">
            <article v-if="operationResult" class="message message-body has-text-centered" :class="messageClass">
                <strong>{{ operationResult }}</strong>
            </article>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters([
            'operationResult',
            'operationLevel'
        ]),
        messageClass() {
            return {
                'ok': this.operationLevel === 'OK',
                'warning': this.operationLevel === 'KO'
            }
        }
    }
}
</script>

<style scoped>
.message-body {
    padding: 0.3rem;
}

.message-body.ok {
    background-color: #388E3C;
    color: white;
}

.message-body.warning {
    background-color: #FFEB3B;
}
</style>