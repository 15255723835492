import VueRouter from "vue-router";
import auth from "./auth.js";
import Vue from "vue";
import store from "./store/index.js";

import { isLoginCode } from './helpers';

function requireAuth(to, from, next) {
    if (!auth.loggedIn()) {
        next({
            path: '/login',
            query: { redirect: to.fullPath }
        });
    } else {
        next();
    }
}

Vue.use(VueRouter);

let routes = [
    {
        path: '/login',
        component: require('./components/login/Login.vue').default,
        meta: { Auth: false },
    },
    {
        path: '/',
        component: require('./components/Dash.vue').default,
        meta: { Auth: true },
        beforeEnter: (to, from, next) => {
            let loggedIn = auth.loggedIn();
            if (loggedIn && from.path === '/' && to.path === '/') {
                next({ path: 'plan' })
            } else {
                next();
            }
        },
        children: [
            {
                path: 'plan',
                component: require('./components/PlanTab.vue').default,
                name: 'home'
            },
            {
                path: 'check',
                name: 'check',
                component: require('./components/tabs/CheckTab.vue').default,
            },
            {
                path: 'check/:orderId',
                name: 'checkOrder',
                component: require('./components/Check.vue').default,
            },
            {
                path: 'customization',
                name: 'customization',
                component: require('./components/tabs/CustomizationTab.vue').default,
            },
            {
                path: 'customization/:orderId',
                name: 'customizationOrder',
                component: require('./components/Customization.vue').default,
            },
            {
                path: 'gift-wrap',
                name: 'gift-wrap',
                component: require('./components/tabs/GiftWrapTab.vue').default,
            },
            {
                path: 'gift-wrap/:orderId',
                name: 'gift-wrapOrder',
                component: require('./components/Wrap.vue').default,
            },
            {
                path: 'closing',
                name: 'closing',
                component: require('./components/tabs/ClosingTab.vue').default,
            },
            {
                path: 'closing/:orderId',
                name: 'closingOrder',
                component: require('./components/Closing.vue').default,
            },
            {
                path: 'print',
                name: 'print',
                component: require('./components/tabs/PrintTab.vue').default
            }
        ]
    },
];

const router = new VueRouter({
    mode: "history",
    routes: routes,
    linkActiveClass: 'is-active'
});


router.beforeEach((to, from, next) => {
    let timeoutAuthLogin = getCookie('timeoutAuthLogin');
    if (to.path.includes('login')) {
        timeoutAuthLogin = 1;
    }
    if (from.name === "closingOrder" && to.name === "gift-wrapOrder") {
        store.commit('SET_CTW', true);
    } else {
        store.commit('SET_CTW', false);
    }
    if ((to.matched.some(record => record.meta.Auth) && !localStorage.token) || timeoutAuthLogin != 1) {
        next({ path: '/login' })
    } else {
        let authCode = undefined;
        if (!_.isEmpty(to.query)) {
            authCode = to.query.text;
        } else if (to.params) {
            authCode = to.params.orderId;
        }

        if (isLoginCode(authCode)) {
            auth.fastLogin(authCode, loginSuccess => {
                if (loginSuccess) {
                    setCookie('timeoutAuthLogin', 1, 1);
                    store.dispatch('loadUser');
                } else {
                    auth.logout(() => next({ path: '/login' }));
                }
            });
        } else {
            setCookie('timeoutAuthLogin', 1, 1);
            next();
        }
    }
});

export function setCookie(cname, cvalue, exhours) {
    let d = new Date();
    d.setTime(d.getTime() + (exhours * 60 * 60 * 1000));
    let expires = "expires=" + d.toUTCString();
    document.cookie = cname + "=" + cvalue + "; path=/; " + expires;
}

export function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

export default router;