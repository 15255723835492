import Vue from "vue";
import axios from 'axios';
import router from "./router.js";
import store from "./store/index.js";

const App = require('./App.vue').default;

axios.defaults.baseURL = process.env.API_BASE_URL;

axios.interceptors.request.use((config) => {
    let token = localStorage.getItem('token');

    if (token !== null) {
        config.headers.Authorization = `Bearer ${token}`;
    }

    let ip = localStorage.getItem('ip');

    if (ip !== null) {
        config.headers['X-Client-Ip'] = ip;
    }

    return config;
}, (err) => {
    return Promise.reject(err);
});

require('./filters');

new Vue({
    store: store,
    router: router,
    render: (h) => h(App),
}).$mount("#app");