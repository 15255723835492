<template>
    <div v-if="loading">
        <load-spinner></load-spinner>
    </div>
    <div v-else>
        <table class="table order-list">
            <thead>
            <tr>
                <th v-if="haveBatch" style="width: 15px">
                    <abbr title="Seleziona">
                        <input type="checkbox" v-model="selectAll" @click="toggleSelectAll">
                    </abbr>
                </th>
                <!-- Ordine -->
                <th>Ordine</th>
                <!-- Tipologia -->
                <th></th>
                <!-- Cliente -->
                <th>Cliente</th>
                <!-- Destinazione -->
                <th>Dest.</th>
                <!-- Info -->
                <th class="has-text-centered">Info</th>
                <!-- Data -->
                <th>
                    <sortable-column column-key="date">Data</sortable-column>
                </th>
                <!-- Peso/Prezzi -->
                <th>Peso/Pezzi</th>
                <!-- Avanzamento -->
                <th>Avanzamento</th>
                <!-- Vettore -->
                <th>
                    <sortable-column column-key="carrier">Vettore</sortable-column>
                </th>
                <!-- Azioni -->
                <th>&nbsp;</th>
            </tr>
            </thead>
            <tbody>
            <order-list-row v-for="order in list" :order="order" :haveBatch="haveBatch" :rowFunction="rowFunction"
                            :delete-function="deleteFunction" :enable-print="enablePrint" :key="order.id"/>
            </tbody>
        </table>
        <div class="columns">
            <div class="column">
                <strong>{{ total }}</strong> Ordini - selezionati: <strong>{{ selectedOrders.length }}</strong> ordini
                su <strong>{{ total }}</strong>.
            </div>
        </div>


        <slot></slot>

    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

const LoadSpinner = require('./containers/LoadSpinner.vue').default;
const Orders = require('../models/Orders.js').default;
const OrderDetail = require('./OrderDetail.vue').default;
const OrderListRow = require('./OrderListRow.vue').default;
const SortableColumn = require('./containers/SortableColumn.vue').default;

const { status: translate } = require('../locales/translate.js');
const { updateQueryStringParameter } = require('../helpers.js');

export default {
    props: ['haveBatch', 'rowFunction', 'deleteFunction', 'enablePrint'],
    data() {
        return {
            pdf: '',
            selectAll: false,
            modalDetailsOpen: false,
            details: null,
        }
    },
    computed: {
        ...mapState({
            loading: state => state.orders.loading,
            selectedOrders: state => state.orders.selected,
            currentPage: state => state.orders.currentPage,
            lastPage: state => state.orders.lastPage,
            perPage: state => state.orders.perPage,
            total: state => state.orders.total,
        }),
        ...mapGetters([
            'list',
            'ordersType'
        ]),
    },
    watch: {
        selectedOrders: function () {
            if (this.selectedOrders.length === 0) {
                this.selectAll = false;
            }
        }
    },
    methods: {
        ...mapActions([
            'resetCheckOrder',
        ]),

        showDetails(orderId) {
            Orders.getDetails(orderId, details => {
                this.details = details;
                this.modalDetailsOpen = true;
            });
        },

        translateStatus(status) {
            return translate(status);
        },

        toggleSelectAll() {
            this.$store.dispatch('toggleSelectAll', { selectAll: this.selectAll });
        },

        queryString(n) {
            let currentURL = window.location.href;

            return updateQueryStringParameter(currentURL, 'page', n);
        },
    },
    components: {
        loadSpinner: LoadSpinner,
        orderDetail: OrderDetail,
        OrderListRow,
        SortableColumn
    },
}
</script>

<style>
.sc {
    position: absolute;
    margin-left: 5px;
}

.sc-up {
    top: -1px;
}

.sc-down {
    top: 10px;
}
</style>
