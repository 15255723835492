import axios from 'axios'

class User {
    static getProfile(then) {
        return axios.get('user/profile', {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        }).then(response => then(response.data));
    }

    static authenticate(then) {
        return axios.get('user/authenticate', {
            headers: { 'Authorization': 'Bearer ' + localStorage.getItem('token') }
        }).then(response => then(response.data));
    }

    static createWorkstation(ip) {
        return axios.post('fulfilment/create-workstation', { ip });
    }
}

export default User;