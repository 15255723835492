import Vue from 'vue'
import Vuex from 'vuex';
import createLogger from 'vuex/dist/logger'

const statistics = require('./modules/statistics').default;
const orders = require('./modules/orders').default;
const search = require('./modules/search').default;
const operation = require('./modules/operation').default;
const check = require('./modules/check').default;
const customization = require('./modules/customization').default;
const wrap = require('./modules/wrap').default;
const user = require('./modules/user').default;
const close = require('./modules/close').default;
const notes = require('./modules/notes').default;
const packinglist = require('./modules/packinglist').default;
const errors = require('./modules/errors').default;
const manualShipment = require('./modules/manual_shipment').default;

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
    modules: {
        statistics,
        orders,
        search,
        operation,
        check,
        customization,
        wrap,
        user,
        packinglist,
        close,
        notes,
        errors,
        manualShipment,
    },
    strict: debug,
    plugins: debug ? [createLogger()] : []
});
