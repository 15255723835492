export const TOGGLE_ORDER_TO_SELECTED = 'TOGGLE_ORDER_TO_SELECTED';
export const TOGGLE_SELECT_ALL = 'TOGGLE_SELECT_ALL';

export const ORDER_LIST_REQUEST = 'ORDER_LIST_REQUEST';
export const ORDER_LIST_SUCCESS = 'ORDER_LIST_SUCCESS';
export const ORDER_LIST_FAILURE = 'ORDER_LIST_FAILURE';

export const LOAD_ORDER_NOTES_SUCCESS = 'LOAD_ORDER_NOTES_SUCCESS';
export const UPDATE_SAW_PHOTOS = 'UPDATE_SAW_PHOTOS';

export const STATUS_INFO_SUCCESS = 'STATUS_INFO_SUCCESS';
export const CHECK_REQUEST = 'CHECK_REQUEST';
export const CHECK_SUCCESS = 'CHECK_SUCCESS';

export const CUSTOMIZED_ITEM_SUCCESS = 'CUSTOMIZED_ITEM_SUCCESS';
export const CUSTOMIZATION_INFO_SUCCESS = 'CUSTOMIZATION_INFO_SUCCESS';
export const CLOSE_INFO_SUCCESS = 'CLOSE_INFO_SUCCESS';
export const REMOVE_CUSTOMIZATION_SUCCESS = 'REMOVE_CUSTOMIZATION_SUCCESS';

export const WRAPPED_ITEM_SUCCESS = 'WRAPPED_ITEM_SUCCESS';
export const WRAP_INFO_SUCCESS = 'WRAP_INFO_SUCCESS';
export const REMOVE_WRAP_SUCCESS = 'REMOVE_WRAP_SUCCESS';

export const SET_PACKING_LIST = 'SET_PACKING_LIST';
export const SET_CURRENT_PACKAGE = 'SET_CURRENT_PACKAGE';
export const ADD_IN_BOX_SUCCESS = 'ADD_IN_BOX_SUCCESS';
export const ADD_IN_BOX_UNEXPECTED = 'ADD_IN_BOX_UNEXPECTED';
export const UNDO_CHECK = 'UNDO_CHECK';

export const LOAD_BOX = 'LOAD_BOX';
export const RESET_CHECK_ORDER = 'RESET_CHECK_ORDER';

export const CHANGE_BOX_REQUEST = 'CHANGE_BOX_REQUEST';
export const CHANGE_BOX_SUCCESS = 'CHANGE_BOX_SUCCESS';
export const CHANGE_BOX_FAILURE = 'CHANGE_BOX_FAILURE';

export const INSERT_NEW_PACKAGE = 'INSERT_NEW_PACKAGE';

export const UPDATE_BOX_WEIGHT_REQUEST = 'UPDATE_BOX_WEIGHT_REQUEST';
export const UPDATE_BOX_WEIGHT_SUCCESS = 'UPDATE_BOX_WEIGHT_SUCCESS';
export const UPDATE_BOX_WEIGHT_FAILURE = 'UPDATE_BOX_WEIGHT_FAILURE';

export const SCAN_ITEM_REQUEST = 'SCAN_ITEM_REQUEST';
export const SCAN_ITEM_UNEXPECTED = 'SCAN_ITEM_UNEXPECTED';
export const SCAN_ITEM_NOT_FOUND = 'SCAN_ITEM_NOT_FOUND';

export const RESET_QUANTITY = 'RESET_QUANTITY';

export const REMOVE_ITEM_REQUEST = 'REMOVE_ITEM_REQUEST';
export const REMOVE_ITEM_SUCCESS = 'REMOVE_ITEM_SUCCESS';

export const END_CHECK_REQUEST = 'END_CHECK_REQUEST';
export const END_CHECK_SUCCESS = 'END_CHECK_SUCCESS';
export const END_CHECK_ERROR = 'END_CHECK_ERROR';

export const USER_PROFILE_REQUEST = 'USER_PROFILE_REQUEST';
export const USER_PROFILE_SUCCESS = 'USER_PROFILE_SUCCESS';
export const USER_PROFILE_FAILURE = 'USER_PROFILE_FAILURE';

export const LOAD_CLOSE_ORDER_REQUEST = 'LOAD_CLOSE_ORDER_REQUEST';
export const LOAD_CLOSE_ORDER_SUCCESS = 'LOAD_CLOSE_ORDER_SUCCESS';
export const LOAD_CLOSE_ORDER_FAILURE = 'LOAD_CLOSE_ORDER_FAILURE';

export const LOAD_CARRIERS_SUCCESS = 'LOAD_CARRIERS_SUCCESS';

export const AVAILABLE_PACKAGES_SUCCESS = 'AVAILABLE_PACKAGES_SUCCESS';

export const UPDATE_SELECTED_TYPOLOGY = 'UPDATE_SELECTED_TYPOLOGY';
export const UPDATE_SEARCH_TEXT = 'UPDATE_SEARCH_TEXT';
export const UPDATE_SELECTED_CARRIER = 'UPDATE_SELECTED_CARRIER';
export const UPDATE_CUSTOMER_TYPE = 'UPDATE_CUSTOMER_TYPE';
export const UPDATE_SEARCH_URGENT = 'UPDATE_SEARCH_URGENT';

export const LOAD_OPERATION_DETAIL = 'LOAD_OPERATION_DETAIL';
export const CLOSE_OPERATION_DETAIL = 'CLOSE_OPERATION_DETAIL';
export const SET_ORDERS_TYPE = 'SET_ORDERS_TYPE';
export const SET_OPERATION_SOUNDS = 'SET_OPERATION_SOUNDS';
export const SET_ORDER = 'SET_ORDER';
export const ORDER_LOADED_SUCCESS = 'ORDER_LOADED_SUCCESS';
export const ORDER_LOADED_FAILURE = 'ORDER_LOADED_FAILURE';
export const RESET_OPERATION = 'RESET_OPERATION';
export const SET_COMMAND = 'SET_COMMAND';
export const SET_QUANTITY = 'SET_QUANTITY';
export const SET_OPERATION_RESULT = 'SET_OPERATION_RESULT';
export const RESET_OPERATION_RESULT = 'RESET_OPERATION_RESULT';
export const RESET_COMMAND = 'RESET_COMMAND';
export const END_PROCESS_SUCCESS = 'END_PROCESS_SUCCESS';

export const ORDERS_STATISTICS_SUCCESS = 'ORDERS_STATISTICS_SUCCESS';
export const NOTIFICATION_READ_SUCCESS = 'NOTIFICATION_READ_SUCCESS';

export const RESET_SEARCH = 'RESET_SEARCH';

export const SET_SERVER_ERROR = 'SET_SERVER_ERROR';
export const RESET_SERVER_ERROR = 'RESET_SERVER_ERROR';

export const START_SCAN = 'START_SCAN';
export const END_SCAN = 'END_SCAN';

export const START_DELETE = 'START_DELETE';
export const END_DELETE = 'END_DELETE';

export const CHANGE_BOX_START = 'CHANGE_BOX_START';
export const CHANGE_BOX_END = 'CHANGE_BOX_END';

export const ENABLE_FOCUS = 'ENABLE_FOCUS';
export const DISABLE_FOCUS = 'DISABLE_FOCUS';

export const SHOW_MANUAL_SHIPMENT = 'SHOW_MANUAL_SHIPMENT';
export const HIDE_MANUAL_SHIPMENT = 'HIDE_MANUAL_SHIPMENT';

export const SET_CTW = 'SET_CTW';
export const FULFILMENT_ON_WORK_FAILURE = 'FULFILMENT_ON_WORK_FAILURE';
