<template>
    <operation :enable-print="true"></operation>
</template>

<script>
const { TO_WRAP_TYPE } = require('../../constants.js');
const { RESET_SEARCH } = require('../../store/mutation-types.js');

const Operation = require('../Operation.vue').default;

export default {
    created() {
        this.$store.dispatch('setType', TO_WRAP_TYPE);
        this.$store.dispatch('loadOrders', {
            page: this.$route.query.page,
            paginate: this.$route.query.paginate
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit(RESET_SEARCH);
        next();
    },
    components: {
        operation: Operation,
    }
}
</script>