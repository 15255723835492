<template>
    <div class="modal is-active">
        <div class="modal-background" @click="$emit('close')"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Modal title</p>
                <button class="delete" @click="$emit('close')"></button>
            </header>
            <section class="modal-card-body">
                <article v-for="row in rows" class="media">
                    <figure class="media-left">
                        <p class="image is-64x64">
                            <img :src="imageUrl(row)" alt="">
                        </p>
                    </figure>
                    <div class="media-content">
                        <div class="content">
                            <p>
                                <strong>{{ row.product_code }}</strong>
                                <br>
                                {{ row.product_name }} {{ row.product_description }} {{ row.color }}
                            </p>
                        </div>
                    </div>
                </article>
            </section>
        </div>
    </div>
</template>

<script>
// import { IMAGES_URL } from '../../config';

export default {
    props: ['details'],
    computed: {
        rows() {
            return this.details.rows;
        }
    },
    methods: {
        imageUrl(row) {
            let { product_id, item_id, image_name } = row;
            return `${IMAGES_URL}/products/${product_id}/${item_id}/main/128-${image_name}`;
        },
    }
}
</script>