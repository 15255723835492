<template>
    <form @submit.prevent="search">
        <div class="columns is-pulled-right" style="padding-right: 110px;padding-bottom: 30px;">
            <!-- /.Customer Type selection -->
            <div class="column">
                <label class="label">Priorità</label>
                <p class="control">
            <span class="select">
                <!--<select @change="updateSelectedTypology($event.target.value)">-->
                <select v-model="selectedCustomer">
                    <option v-for="customer in availableCustomers" :value="customer.id">{{ customer.text }}</option>
                </select>
            </span>
                </p>
            </div>
            <!-- /.End Customer Type selection -->

            <!-- /.Carrier selection -->
            <div class="column">
                <label class="label">Vettore</label>
                <p class="control">
                <span class="select">
                    <select v-model="selectedCarrier">
                        <option value="ALL">Tutti</option>
                        <option v-for="carrier in availableCarriers" :value="carrier.id">{{ carrier.name }}</option>
                    </select>
                </span>
                </p>
            </div>
            <!-- /.End Carrier selection -->

            <!-- /.Free Text Search -->
            <div class="column">
                <label for="text" class="label">Cerca</label>
                <div class="field has-addons">
                    <p class="control is-expanded">
                        <input type="text" class="input" v-model="text" ref="text" id="text">
                    </p>
                    <p class="control is-expanded">
                        <button type="submit" class="button is-info">Invia</button>
                    </p>
                </div>
            </div>
            <!-- /.Free Text Search -->
        </div>
    </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { isLoginCode } from '../helpers';

export default {
    data() {
        return {
            orderStatuses: [],
            orderId: '',
            focused: true
        }
    },

    computed: {
        ...mapGetters([
            'availableCustomers',
            'availableCarriers',
            'processType'
        ]),

        text: {
            get() {
                return this.$store.state.search.text;
            },
            set(text) {
                this.updateSearchText(text);
            }
        },
        selectedCarrier: {
            get() {
                return this.$store.state.search.selectedCarrier;
            },
            set(carrierId) {
                this.updateSelectedCarrier(carrierId);
            }
        },
        selectedCustomer: {
            get() {
                return this.$store.state.search.selectedCustomer;
            },
            set(customerType) {
                this.updateCustomerType(customerType);
            }
        },
        searchUrgent: {
            get() {
                return this.$store.state.search.urgent;
            },
            set(urgent) {
                this.updateSearchUrgent(urgent);
            }
        }
    },

    mounted() {
        this.$refs.text.focus();
    },

    methods: {
        ...mapActions([
            'updateSelectedTypology',
            'updateSearchText',
            'updateSelectedCarrier',
            'updateCustomerType',
            'updateSearchUrgent',
            'loadOrders'
        ]),

        load() {
            this.loadOrders();
        },

        search() {
            if (this.text) {
                if (!isNaN(this.text) && this.$route.name != 'home') {

                    let orderId = this.text;
                    this.text = null;
                    this.$router.push({

                        name: this.$route.name + 'Order',
                        params: {
                            'orderId': orderId
                        }
                    });
                } else {
                    if (!isNaN(this.text)) {
                        this.text = 'F' + this.text;
                    }
                    this.$router.push({
                        query: {
                            'customer': this.selectedCustomer,
                            'carrier': this.selectedCarrier,
                            'urgent': this.searchUrgent,
                            'text': this.text,
                            'type': this.processType
                        }
                    });

                    if (isLoginCode(this.text)) {
                        this.text = null;
                    }
                }
            } else {
                this.$router.push({
                    query: {
                        'customer': this.selectedCustomer,
                        'carrier': this.selectedCarrier,
                        'urgent': this.searchUrgent,
                        'text': this.text,
                        'type': this.processType
                    }
                })
            }
        },
    },
}
</script>
