const {
    ORDER_LOADED_FAILURE,
    WRAP_INFO_SUCCESS,
    ORDER_LOADED_SUCCESS,
    END_PROCESS_SUCCESS,
    WRAPPED_ITEM_SUCCESS,
    RESET_OPERATION_RESULT,
    SET_OPERATION_RESULT,
    SET_OPERATION_SOUNDS,
    SET_SERVER_ERROR,
    REMOVE_WRAP_SUCCESS
} = require('../mutation-types.js');
const { DONE_STATUS, UNDONE_STATUS } = require('../../constants.js');

const Orders = require('../../models/Orders').default;

import auth from '../../auth';
import { isLoginCode } from '../../helpers';
import router from '../../router.js';

const state = {
    rows: [],
};

const getters = {
    itemsToWrap: state => _.filter(state.rows, { check: 'undone' }),
    itemsWrapped: state => _.filter(state.rows, { check: 'done' }),
};

const actions = {
    loadWrapOrder({ commit, getters }) {
        let orderId = getters.orderId;

        return new Promise((resolve, reject) => {
            Orders.loadWrapOrder(orderId, resp => {
                let { rows } = resp;

                if (rows === undefined) {
                    let { response, description } = resp;
                    commit(ORDER_LOADED_FAILURE, { type: response, message: description });
                } else {
                    commit(WRAP_INFO_SUCCESS, rows);
                    commit(ORDER_LOADED_SUCCESS);
                }

                resolve();
            }).catch(error => {
                commit(ORDER_LOADED_FAILURE, error.response.data);
                reject()
            });
        });
    },

    checkWrap({ commit, getters, dispatch }, method = 'scanner') {
        return new Promise((resolve, reject) => {
            let command = getters.currentCommand;
            let orderId = getters.orderId;

            let data = {
                source: 'WRAP',
                command,
                orderId,
                method
            };

            if (isLoginCode(command)) {
                auth.fastLogin(command, loginSuccess => {
                    if (loginSuccess) {
                        dispatch('loadUser');
                        resolve();
                    } else {
                        auth.logout(() => next({ path: '/login' }));
                    }
                });
            } else {
                Orders.scan(data).then(resp => {
                    let { response, operation, description, sounds, isLast } = resp.data;

                    if (response === 'OK') {
                        switch (operation) {
                            case 'ENDPROCESS':
                                commit(END_PROCESS_SUCCESS);
                                break;
                            case 'CHECKED_ITEM':
                                commit(WRAPPED_ITEM_SUCCESS, parseInt(command));
                                break;
                        }

                        commit(RESET_OPERATION_RESULT);

                        if (isLast === 1) {
                            commit(SET_OPERATION_SOUNDS, { sounds });
                            let data = {
                                source: 'WRAP',
                                command: 'ENDPROCESS',
                                orderId,
                                method
                            };
                            Orders.scan(data).then(resp => {
                                let { response } = resp.data;

                                if (response === 'OK') {
                                    commit(END_PROCESS_SUCCESS);
                                }
                            });
                        }
                    }

                    commit(SET_OPERATION_RESULT, { description, level: response });
                    commit(SET_OPERATION_SOUNDS, { sounds });

                    resolve();
                }).catch(error => {
                    console.log(error);
                    commit(SET_SERVER_ERROR, error);
                    reject();
                });
            }
        }).then(() => {
            if (getters.closingToWrap) {
                router.push({
                    name: 'closingOrder',
                    params: {
                        'orderId': orderId
                    }
                });
            }
        })
    },

    removeWrap({ commit }, fulfilment_order_product_id) {
        return new Promise((resolve, reject) => {
            Orders.removeWrap(fulfilment_order_product_id)
                .then(response => {
                    commit(REMOVE_WRAP_SUCCESS, fulfilment_order_product_id);
                    commit(RESET_OPERATION_RESULT);
                }).catch(error => {
                commit(SET_SERVER_ERROR, error);
            });
        })
    }
};

const mutations = {
    [WRAP_INFO_SUCCESS](state, rows) {
        state.rows = rows;
    },

    [WRAPPED_ITEM_SUCCESS](state, fulfilment_order_product_id) {
        let wrappedRow = _.find(state.rows, { fulfilment_order_product_id });

        wrappedRow.check = DONE_STATUS;
    },

    [REMOVE_WRAP_SUCCESS](state, fulfilment_order_product_id) {
        let wrappedRow = _.find(state.rows, { fulfilment_order_product_id });

        wrappedRow.check = UNDONE_STATUS;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}
