<template>
    <div class="modal" :class="{ 'is-active' : localIsActive }">
        <div class="modal-background" @click="close"></div>
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Riepilogo Scatole</p>
                <button class="delete" @click="close"></button>
            </header>
            <section class="modal-card-body">
                <collapse accordion is-fullwidth>
                    <collapse-item v-for="box in packages" :title="box.type" :key="box.id" selected>
                        <nav class="panel">
                            <div v-for="row in getContent(box.rows)" class="panel-block">
                                <span class="panel-icon">
                                    <i class="fa" :class="rowIcon(row.check)"></i>
                                </span>
                                <strong>{{ row.qty }}x</strong> &nbsp;<i>{{
                                    row.product_code
                                }}</i>&nbsp;{{ row.product_name }} {{ row.product_description }} {{ row.item_color }}
                            </div>
                        </nav>
                    </collapse-item>
                </collapse>
            </section>
        </div>
    </div>
</template>

<script>
const Collapse = require('./collapse/Collapse').default;
const CollapseItem = require('./collapse/CollapseItem').default;
const { ROW_TYPE_ERROR, ROW_TYPE_COMMON } = require('../constants.js');

export default {
    props: ['isActive'],
    data() {
        return {
            localIsActive: this.isActive,
        }
    },
    computed: {
        packages() {
            return this.$store.getters.packages;
        },
        items() {
            return this.$store.state.check.rows;
        }
    },
    methods: {
        close() {
            this.localIsActive = false;
        },
        rowIcon(status) {
            return status == 'done'
                ? {
                    'fa-check-circle-o': true,
                    'green': true
                } : {
                    'fa-times-circle-o': true,
                    'red': true
                };
        },
        getContent(rows) {
            let packageContent = rows.map(fulfilmentId => {
                return this.items.find(element => {
                    return element.fulfilment_order_product_id === fulfilmentId;
                });
            });

            let items = [];
            // Error items are always on top
            let error = _.filter(packageContent, x => x.type == ROW_TYPE_ERROR);

            error.forEach(x => items.push(x));

            let simple = _.filter(packageContent, x => x.type == ROW_TYPE_COMMON);
            let group = _.groupBy(simple, 'key');

            _.keys(group).forEach(key => {
                let item = group[key][0];
                items.push(Object.assign({}, item, { qty: group[key].length }));
            });

            return items;
        }
    },
    watch: {
        isActive() {
            this.localIsActive = true;
        }
    },
    components: {
        Collapse,
        CollapseItem
    }
}
</script>

<style scoped>
.red {
    color: red;
}

.green {
    color: green;
}
</style>
