<template>
    <div>
        <router-view></router-view>
        <div id="app"></div>
        <error-modal></error-modal>
    </div>
</template>

<script>
const ErrorModal = require('./components/containers/ErrorModal.vue').default;

export default {
    name: 'app',
    components: { ErrorModal },
    watch:{
        $route (to){
            if (to.path != '/login') {
                let pattern = /^.+?[^\/:](?=[?\/]|$)/g;
                document.cookie="path=" + to.path.match(pattern);
            }
        }
    }
}
</script>

<style src="./assets/global.scss" lang="scss"></style>