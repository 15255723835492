const { USER_PROFILE_SUCCESS, FULFILMENT_ON_WORK_FAILURE } = require('../mutation-types.js');
const User = require('../../models/User').default;
import _ from 'lodash';

const state = {
    currentUser: []
};

// getters
const getters = {
    userFullName: state => _.startCase(_.toLower(state.currentUser.full_name)),
    userId: state => state.currentUser.id,
};

// actions
const actions = {
    loadUser({ commit, state }) {
        User.getProfile(response => {
            let { user } = response;
            commit(USER_PROFILE_SUCCESS, { user });
        }).catch(error => commit(FULFILMENT_ON_WORK_FAILURE));
    },
};

// mutations
const mutations = {
    [USER_PROFILE_SUCCESS](state, { user }) {
        state.currentUser = user;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}