<template>
    <tr :style="order.unprocessable ? 'font-style: italic; color: #EF5350' : ''">
        <td v-if="order.pieces > 100 && haveBatch"></td>
        <td v-else-if="haveBatch" style="text-align: center; cursor: pointer;" @click="toggleSelection(order)">
            <input type="checkbox" :value="order.id" v-model="selectedOrders" :disabled="order.unprocessable">
        </td>
        <!-- Ordine -->
        <td>
            <div v-if="order.unprocessable_reason">
                <span class="hint--bottom" :aria-label="order.unprocessable_reason">{{ order.id }}</span>
            </div>
            <div v-else><b>F{{ order.id }}</b></div>
        </td>
        <!-- Tipologia -->
        <td>
            <i v-if="order.is_marketplace" class="fa fa-amazon" style="font-size: 1rem; vertical-align: baseline">
                <div class="tooltip_title">
                    Amazon
                </div>
            </i>
            <i v-if="!order.is_customer" class="fa fa-circle" style="color: red; font-size: 1rem; vertical-align: baseline">
                <div class="tooltip_title">
                    Rivenditore
                </div>
            </i>
        </td>
        <!-- Cliente -->
        <td>{{ order.warehouse_billing_name }}</td>
        <!-- Destinazione -->
        <td>{{ order.country.name }}</td>
        <!-- Info -->
        <td class="has-text-centered">
            <p class="field">
                <span class="circle" v-if="order.urgent_shipping">
                    <i class="fa fa-exclamation urgent__shipping" style="font-size: 13px" aria-hidden="true"></i>
                </span>
                <span v-if="order.customization > 0">
                    <i style="font-weight: 700; font-size: 13px">P</i>
                    <span style="font-size: 12px; margin-left: -4px">({{ order.customization }})</span>
                </span>
                <span v-if="order.wrap > 0">
                    <i class="fa fa-gift" aria-hidden="true" style="font-size: 13px; margin-top: 5px"></i>
                    <span style="font-size: 12px; margin-left: -4px">({{ order.wrap }})</span>
                </span>
                <span v-if="order.giftwrap_messages > 0">
                    <i class="fa fa-envelope" aria-hidden="true" style="font-size: 13px; margin-top: 5px"></i>
                    <span style="font-size: 12px; margin-left: -4px">({{ order.giftwrap_messages }})</span>
                </span>
                <span v-if="order.notes.length > 0">
                    <span class="hint--bottom hint--large" :aria-label="flatNote"><i class="fa fa-comments"
                                                                                     aria-hidden="true"></i></span>
                </span>
            </p>
        </td>
        <!-- Data -->
        <td>
            {{ order.created_at | formatDate }}
        </td>
        <!-- Peso/Prezzi -->
        <td class="has-text-centered">
            <b>{{ order.weight.undone }}</b> ({{ order.pieces }})
        </td>
        <!-- Avanzamento -->
        <td>
            <div class="w3-light-grey">
                <div class="w3-container w3-center" :class="progressColor" :style="{ width: percentage + '%'}">
                    {{ percentage }}%
                </div>
            </div>
        </td>
        <!-- Vettore -->
        <td>
            <figure v-if="order.carrier_service" class="image is-48x48" style="height: inherit">
                <img :src="order.logo_url">
            </figure>
            <span v-else>
                In Sede
            </span>
        </td>
        <!-- Azioni -->
        <td>
            <div v-if="!order.unprocessable">
                <p class="field">
                    <button
                        v-if="rowFunction"
                        class="button is-success is-small"
                        :class="loading ? 'is-loading' : ''"
                        @click="executeAction(order.id)"
                        :disabled="loading"
                    >
                        Esegui
                    </button>
                    <router-link
                        v-else :to="buildLink"
                        class="button is-success is-small"
                        :class="loading ? 'is-loading' : ''"
                        :disabled="loading"
                    >
                        <b>Esegui</b>
                    </router-link>
                    <a href="#" class="button is-small" v-if="enablePrint" title="Stampa" @click="printOrder">
                        <span class="icon is-small">
                          <i class="fa fa-print"></i>
                        </span>
                    </a>
                </p>
            </div>
            <div v-else>
                <button
                    class="button is-danger is-small"
                    :class="loading ? 'is-loading' : ''"
                    @click="executeAction(order.id)"
                    :disabled="loading"
                >
                    Elimina
                </button>
            </div>
        </td>
    </tr>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

const Print = require('../models/Print').default;

export default {
    props: ['order', 'haveBatch', 'rowFunction', 'deleteFunction', 'enablePrint'],
    data() {
        return {
            loading: false,
        }
    },
    computed: {
        ...mapState({
            selectedOrders: state => state.orders.selected,
        }),
        ...mapGetters([
            'ordersType',
            'rowProcessType'
        ]),
        processQuantity() {
            return _.filter(this.order.processes_quantity, { fulfilment_process_id: this.rowProcessType });
        },
        buildLink() {
            return this.$route.path + '/' + this.order.id;
        },
        doneQuantity() {
            let doneQuantity = _.find(this.processQuantity, { status: 'done' });
            return _.get(doneQuantity, 'total') || 0;
        },
        undoneQuantity() {
            let undoneQuantity = _.find(this.processQuantity, { status: 'undone' });
            return _.get(undoneQuantity, 'total') || 0;
        },
        errorQuantity() {
            let errorQuantity = _.find(this.processQuantity, { status: 'error' });
            return _.get(errorQuantity, 'total') || 0;
        },
        totalProcesses() {
            return this.doneQuantity + this.undoneQuantity;
        },

        percentage() {
            if (this.totalProcesses === 0) return 100;
            return Math.round((this.doneQuantity / this.totalProcesses) * 100);
        },

        progressColor() {
            let orderComplete = this.doneQuantity >= this.totalProcesses;
            return this.errorQuantity > 0 ? 'w3-danger' : !orderComplete ? 'w3-warning' : 'w3-success';
        },

        flatNote() {
            let html = _.map(this.order.notes, 'body').join(' | ');
            let doc = new DOMParser().parseFromString(html, 'text/html');
            return doc.body.textContent || "";
        }
    },

    methods: {
        ...mapActions([
            'setError',
        ]),
        toggleSelection(order) {
            this.selectAll = true;
            this.$store.dispatch('toggleOrderToSelected', order.id);
        },

        async executeAction(id) {
            this.loading = true;
            let q = true;
            if (this.order.pieces > 100) {
                q = confirm('Generalmente per un fulfilment con un numero elevato di pezzi è preferible procedere con la generazione automatica dei processi. Vuoi comunque procedere manualmente?');
            }
            if (q) {
                try {
                    let func = this.rowFunction(id);
                    if (this.order.unprocessable) {
                        func = this.deleteFunction(id);
                    }
                    await func;
                } catch (ex) {
                    this.setError(ex);
                }
            }
            this.loading = false;
        },

        printOrder() {
            Print.printOrder(this.order.id);
        }
    }
}
</script>

<style lang="scss">
.progress-container {
    align-items: center;
    margin-bottom: 20px;

    .progress {
        position: relative;
        margin-bottom: 0 !important;
    }

    .progress + span {
        margin-left: 10px;
        min-width: 36px;
        text-align: right;
    }
}

.hint--bottom {
    display: inline !important;
}

.circle {
    padding: 4px 3px 0 3px;
    height: 18px;
    border-radius: 50%;
    background: #FDD835;
    display: inline-block;
    text-align: center;
    min-width: 18px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, .3);
    -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, .3);
}

.urgent__shipping {
    font-size: 1.6rem;
    color: red;
}

.w3-light-grey, .w3-hover-light-grey:hover, .w3-light-gray, .w3-hover-light-gray:hover {
    color: #000 !important;
    background-color: #f1f1f1 !important;
}

.w3-success {
    color: #fff !important;
    background-color: #4CAF50 !important;
}

.w3-warning {
    color: #000 !important;
    background-color: #FFEB3B !important;
}

.w3-danger {
    color: #fff !important;
    background-color: #F44336 !important;
}

</style>