const {
    UPDATE_SELECTED_TYPOLOGY,
    UPDATE_SELECTED_CARRIER,
    UPDATE_CUSTOMER_TYPE,
    UPDATE_SEARCH_TEXT,
    UPDATE_SEARCH_URGENT,
    LOAD_CARRIERS_SUCCESS,
    RESET_SEARCH
} = require('../mutation-types.js');

const state = {
    selectedCarrier: 'ALL',
    carriers: [],
    selectedCustomer: 'ALL',
    customers: [
        { id: 'ALL', 'text': 'Tutti' },
        { id: 'URGENT', 'text': 'Urgenti' },
        { id: 'AMAZON', 'text': 'Amazon' },
        { id: 'EXPRESS', 'text': 'Express' },
    ],
    text: '',
    urgent: false,
};

const getters = {
    selectedTypology: state => state.selectedTypology,
    searchText: state => state.text,
    selectedSearchCustomer: state => state.selectedCustomer,
    availableCustomers: state => state.customers,
    selectedSearchCarrier: state => state.selectedCarrier,
    availableCarriers: state => state.carriers,
    searchUrgent: state => state.urgent,
};

const actions = {
    updateSelectedTypology: ({ commit }, selected) => commit(UPDATE_SELECTED_TYPOLOGY, selected),
    updateSelectedCarrier: ({ commit }, carrierId) => commit(UPDATE_SELECTED_CARRIER, carrierId),
    updateCustomerType: ({ commit }, customerType) => commit(UPDATE_CUSTOMER_TYPE, customerType),
    updateSearchText: ({ commit }, text) => commit(UPDATE_SEARCH_TEXT, text),
    updateSearchUrgent: ({ commit }, urgent) => commit(UPDATE_SEARCH_URGENT, urgent),
};

const mutations = {
    [UPDATE_SELECTED_TYPOLOGY](state, selected) {
        state.selectedTypology = selected;
    },
    [UPDATE_SELECTED_CARRIER](state, carrierId) {
        state.selectedCarrier = carrierId;
    },
    [UPDATE_CUSTOMER_TYPE](state, customerType) {
        state.selectedCustomer = customerType;
    },
    [UPDATE_SEARCH_TEXT](state, text) {
        state.text = text;
    },
    [UPDATE_SEARCH_URGENT](state, urgent) {
        state.urgent = urgent;
    },
    [LOAD_CARRIERS_SUCCESS](state, carriers) {
        state.carriers = carriers;
    },
    [RESET_SEARCH](state) {
        state.selectedCustomer = 'ALL';
        state.text = null;
        state.urgent = false;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
