<template>
    <div class="modal" :class="isActive">
        <div class="modal-background" v-on:click.self="closeModal"></div>
        <div class="modal-card error-modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">Errore</p>
                <button class="delete" v-on:click="closeModal"></button>
            </header>
            <section class="modal-card-body">
                <div v-html="serverError"></div>
            </section>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters([
            'serverError'
        ]),
        isActive() {
            return {
                'is-active': this.serverError !== null,
            }
        }
    },
    methods: {
        ...mapActions([
            'resetError'
        ]),
        closeModal() {
            this.resetError();
        }
    }
}
</script>

<style>
.error-modal-card {
    width: 60%;
}
</style>