import axios from 'axios'

class Orders {
    static load(query) {
        return axios.get('fulfilment/load-orders', {
            params: query
        });
    }

    static delete(orderId) {
        return axios.post(`/fulfilment/delete`, { orderId });
    }

    static loadStatistics() {
        return axios.get('fulfilment/statistics');
    }

    static getDetails(orderId, then) {
        return axios.get('/fulfilment/order-details/' + orderId).then(response => then(response.data));
    }

    static async loadCheckOrder(orderId, then) {
        return axios.get(`fulfilment/load-check-order/${orderId}`).then(response => then(response.data));
    }

    static loadCustomizeOrder(orderId) {
        return axios.get(`fulfilment/load-customize-order/${orderId}`);
    }

    static loadWrapOrder(orderId, then) {
        return axios.get(`fulfilment/load-wrap-order/${orderId}`).then(response => then(response.data));
    }

    static loadCloseOrder(orderId) {
        return axios.get(`fulfilment/load-close-order/${orderId}`);
    }

    static removeCustomization(fulfilment_order_product_id) {
        return axios.post('fulfilment/customization', { fulfilment_order_product_id });
    }

    static removeWrap(fulfilment_order_product_id) {
        return axios.post('fulfilment/gift-wrap', { fulfilment_order_product_id });
    }

    static changeBox(currentPackage, newBox) {
        let data = {
            packageId: currentPackage,
            newBox
        };

        return axios.post('fulfilment/change-box', data);
    }

    static scan(data) {
        return axios.post('fulfilment/scan', data);
    }

    static ordersStatuses(then) {
        return axios.get('fulfilment/order-statuses').then(response => then(response.data));
    }

    static planOrders(orders, printBoxes, printArticles) {
        let data = {
            orders,
            printBoxes,
            printArticles
        };

        return axios.post('fulfilment/plan-orders', data);
    }

    static planByPieces() {
        return axios.post('fulfilment/plan-by-pieces');
    }

    static loadOrderToClose(orderId, then) {
        return axios.get(`fulfilment/load-close-order/${orderId}`).then(response => then(response.data));
    }

    static confirmReadNote(noteId) {
        return axios.post(`/fulfilment/confirm-read-note`, { noteId });
    }

    static loadNotes(orderId) {
        return axios.get(`fulfilment/${orderId}/notes`);
    }

    static insertNote(orderId, body) {
        return axios.post(`fulfilment/${orderId}/insert-note`, { body });
    }
}

export default Orders;
