const { HIDE_MANUAL_SHIPMENT, SHOW_MANUAL_SHIPMENT } = require('../mutation-types.js');

const state = {
    message: null
}

const mutations = {
    [HIDE_MANUAL_SHIPMENT](state) {
        state.message = null;
    },
    [SHOW_MANUAL_SHIPMENT](state, message) {
        state.message = message;
    }
}

export default {
    state,
    mutations
}