const {
    RESET_OPERATION_RESULT,
    SET_OPERATION_SOUNDS,
    SET_OPERATION_RESULT,
    START_DELETE,
    END_DELETE,
    LOAD_BOX,
    REMOVE_ITEM_SUCCESS,
    SET_PACKING_LIST,
    SET_CURRENT_PACKAGE,
    UNDO_CHECK,
    SET_SERVER_ERROR,
    INSERT_NEW_PACKAGE,
    CHANGE_BOX_SUCCESS,
    AVAILABLE_PACKAGES_SUCCESS,
    UPDATE_BOX_WEIGHT_SUCCESS,
    ADD_IN_BOX_SUCCESS,
    ADD_IN_BOX_UNEXPECTED
} = require('../mutation-types.js');

const { EMPTY_BOX } = require('../../constants.js');

import _ from 'lodash';

const Orders = require('../../models/Orders').default;
const Packages = require('../../models/Packages').default;


function* boxGenerator() {
    let index = 1;
    while (true)
        yield EMPTY_BOX + '_' + index++;
}

const gen = boxGenerator();

const state = {
    boxes: [],
    selected: '',
    available: []
};

// getters
const getters = {
    boxes: state => state.boxes,
    selectedBox: state => state.selected,
    packageContent: (state, getters) => {
        if (state.selected.rows) {
            let rows = getters.orderRows;

            return state.selected.rows.map(package_id => {
                return rows.find(element => {
                    return element.fulfilment_order_product_id === package_id;
                });
            });
        }
    },
    availablePackages: state => state.available,
};

// actions
const actions = {
    loadBox({ commit }, { id }) {
        commit(LOAD_BOX, { id });
    },

    removeFromPackinglist({ commit }, { fulfilment_order_product_id }) {
        commit(RESET_OPERATION_RESULT);
        commit(START_DELETE);
        Packages.removeItem(fulfilment_order_product_id)
            .then(({ data }) => {
                let { description, response, sounds, status } = data;

                commit(REMOVE_ITEM_SUCCESS, { fulfilment_order_product_id });

                let { packages, latest_packinglist_id } = status;
                commit(SET_PACKING_LIST, packages.packages);
                commit(SET_CURRENT_PACKAGE, latest_packinglist_id);
                commit(UNDO_CHECK, { fulfilment_order_product_id });

                commit(SET_OPERATION_RESULT, { description, level: response });
                commit(SET_OPERATION_SOUNDS, { sounds });
                commit(END_DELETE);
            }).catch(error => {
            commit(SET_SERVER_ERROR, error);
            commit(END_DELETE);
        });
    },

    insertBox({ commit }, { packageTypeId, packageName, }) {
        commit(INSERT_NEW_PACKAGE, { id: packageTypeId, type: packageName });
    },

    changeBox({ commit, state }, { newBox }) {
        commit(RESET_OPERATION_RESULT);

        let { id, name } = newBox;
        // If is an empty box, just update local state (this box do not exists on database yet)
        if (String(state.selected.packageId).indexOf(EMPTY_BOX) >= 0) {
            return new Promise((resolve, reject) => {
                commit(CHANGE_BOX_SUCCESS, { id, name });
                resolve();
            });
        } else {
            return new Promise((resolve, reject) => {
                Orders.changeBox(state.selected.packageId, id)
                    .then(response => {
                        commit(CHANGE_BOX_SUCCESS, { id, name });
                        resolve();
                    }).catch(error => {
                    commit(SET_SERVER_ERROR, error);
                    reject();
                });
            });
        }
    },

    loadAvailablePackages({ commit, getters }) {
        let carrierServiceId = getters.carrierService;

        Packages.all(carrierServiceId, boxes => {
            commit(AVAILABLE_PACKAGES_SUCCESS, { boxes });
        })
    },

    updatePackageWeight({ commit, state, getters }) {
        let orderId = getters.orderId;

        Packages.updateWeight(state.selected.packageId, state.selected.weight, orderId)
            .then((resp) => {
                let { response, operation, description } = resp.data;

                if (response === 'WARNING') {
                    let { description } = resp.data;
                    commit(SET_OPERATION_RESULT, description);
                } else if (response === 'KO') {
                    switch (operation) {
                        case 'WEIGHT_LESS_THAN_ZERO':
                            weight = oldWeight;
                            commit(SET_OPERATION_RESULT, description);
                            break;
                    }
                }
            }).catch((error) => {
            commit(SET_SERVER_ERROR, error);
        });
    }
};

// mutations
const mutations = {
    [SET_PACKING_LIST](state, boxes) {
        let pkg = [];
        let masterSelected = false;

        _.forOwn(boxes, (value, packageId) => {
            let obj = {};
            obj['id'] = value.id;
            obj['packageId'] = packageId;
            obj['type'] = value.type;
            obj['weight'] = value.weight;
            obj['rows'] = value.rows;

            if (!masterSelected || packageId.indexOf(EMPTY_BOX) >= 0) {
                obj['master'] = true;
                masterSelected = true;
            }

            pkg.push(obj);
        });

        state.boxes = pkg;
        //state.selected = pkg[pkg.length - 1];
    },

    [SET_CURRENT_PACKAGE](state, packinglist_id) {
        let pkIdx = state.boxes.length - 1;
        if (!!packinglist_id) {
            pkIdx = _.findIndex(state.boxes, p => p.packageId == packinglist_id);
        }

        state.selected = state.boxes[pkIdx];
    },

    [LOAD_BOX](state, { id }) {
        state.selected = _.find(state.boxes, p => {
            let packid = p.packageId;
            return packid == id;
        });

        // Se la scatola selezionata non è l'ultima, al cambio forzo il riempimento di questa.
        // Altrimenti il sistema per default carica sempre nell'ultima
        let boxIdx = _.findIndex(state.boxes, p => p.packageId == id);

        if (boxIdx < state.boxes.length - 1) {
            state.selected.loadInThisBox = true;
        }
    },

    [UPDATE_BOX_WEIGHT_SUCCESS](state, { weight }) {
        state.selected.weight = weight;
    },

    [ADD_IN_BOX_SUCCESS](state, { fulfilment_order_product_found, packing_list_id }) {
        if (fulfilment_order_product_found.constructor === Array) {
            fulfilment_order_product_found.forEach(fop_id => {
                state.selected.rows.unshift(fop_id);
            });
        } else {
            state.selected.rows.unshift(fulfilment_order_product_found);
        }
        state.selected.packageId = packing_list_id;
    },

    [ADD_IN_BOX_UNEXPECTED](state, { packing_list_id, fulfilment_order_product_id }) {
        // If this is the first box, rename it
        if (String(state.selected.packageId).indexOf(EMPTY_BOX) > 0) {
            state.selected.packageId = packing_list_id;
        }
        state.selected.rows.unshift(fulfilment_order_product_id);
    },

    [REMOVE_ITEM_SUCCESS](state, { fulfilment_order_product_id }) {
        let packageIndex = state.selected.rows.findIndex(o => o === fulfilment_order_product_id);

        state.selected.rows.splice(packageIndex, 1);

        if (state.selected.rows.length === 0) {
            if (!state.selected.master) {
                let packageToDelete = state.boxes.findIndex(p => p.packageId === state.selected.packageId);
                state.boxes.splice(packageToDelete, 1);
                state.selected = state.boxes[packageToDelete - 1];
            } else {
                state.selected.packageId = gen.next().value; // Generate a new box when the selected one become empty
            }
        }
    },

    [INSERT_NEW_PACKAGE](state, { id, type }) {
        let newBox = {
            id,
            packageId: gen.next().value,
            type,
            weight: 0,
            rows: [],
            forceInsert: true
        };

        state.boxes.push(newBox);
        state.selected = state.boxes[state.boxes.length - 1]; // Select last box inserted
    },

    [CHANGE_BOX_SUCCESS](state, { id, name }) {
        state.selected.id = id;
        state.selected.type = name;
    },

    [AVAILABLE_PACKAGES_SUCCESS](state, { boxes }) {
        state.available = boxes;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}