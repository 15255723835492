<template>
    <div class="section">
        <div class="container">
            <!-- /.Loading Spinner -->
            <div v-if="orderIsLoading">
                <load-spinner>
                </load-spinner>
            </div>
            <div v-else class="columns">
                <div class="column">
                    <a class="button is-primary" @click="$router.go(-1)">
                        <span class="icon">
                          <i class="fa fa-arrow-circle-left"></i>
                        </span>
                        <span>Indietro</span>
                    </a>
                </div>
                <div class="column">
                    <operation-result></operation-result>
                </div>
                <div class="column">
                    <strong>Ordine: </strong>F{{ orderId }}
                </div>
            </div>
            <!-- /.End Loading Spinner -->

            <hr>

            <div v-if="orderErrorMessage" class="columns">
                <div class="column is-half is-offset-one-quarter">
                    <article class="message has-text-centered" :class="errorTypeClass">
                        <div class="message-body">
                            <strong>{{ orderErrorMessage }}</strong>
                        </div>
                    </article>
                </div>

            </div>

            <div v-if="!orderIsLoading && !orderErrorMessage && orderId">
                <div class="columns">
                    <div class="column is-half is-offset-6">
                        <div class="control is-horizontal">
                            <div class="control-label">
                                <label class="label">Comando</label>
                            </div>
                            <div class="control is-grouped">
                                <p class="control is-expanded" :class="{ 'is-loading' : loading}">
                                    <input class="input"
                                           type="text"
                                           v-model="command"
                                           ref="commandInput"
                                           v-on:keyup.enter="submitScan"
                                           :disabled="loading || changingBox"
                                           v-on:click="enableFocus"
                                    />
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="columns">
                    <div class="column">
                        <div v-for="orderNote in orderNotes" class="notification"
                             :class="{'is-danger' : !orderNote.read_by, 'is-success': orderNote.read_by }">
                            <div style="display: flex; justify-content: space-between">
                                <span class="icon" @click="confirmNoteRead(orderNote)"
                                      v-show="orderNote.showConfirm || orderNote.read_by">
                                    <i class="fa"
                                       :class="[orderNote.read_at ? 'fa-check' : 'fa-exclamation-circle pointer']"></i>
                                </span>
                                <i class="fa fa-picture-o pointer" @click="showNotePictures(orderNote)"
                                   v-if="orderNote.pictures.length > 0"></i>
                            </div>
                            <span>{{ orderNote.created_at | formatDate }}, {{ orderNote.creator }}
                                <span class="is-pulled-right" v-if="orderNote.read_by">
                                    <b>Letta: </b>
                                    <span>{{ orderNote.read_by }}, </span>
                                    <span>{{ orderNote.read_at | formatDate }}</span>
                                </span>
                            </span>

                            <br>
                            <div v-html="orderNote.body"></div>
                        </div>
                    </div>
                    <div class="column">
                        <slot name="added"></slot>
                    </div>
                </div>

                <slot name="content"></slot>
            </div>

            <div class="modal" :class="notePictures.length > 0 ? 'is-active' : ''">
                <div class="modal-background" @click="closeNotePictures"></div>

                <div class="modal-content" style="background-color: transparent; border: none">
                    <div id="carouselImageIndicators" class="carousel slide" data-ride="carousel" data-interval="false">
                        <ol class="carousel-indicators">
                            <li v-for="(image, index) in notePictures" data-target="#carouselImageIndicators" :data-slide-to="index" class="active"></li>
                        </ol>
                        <div class="carousel-inner">
                            <div v-for="(image, index) in notePictures" class="carousel-item" :class="index == 0 ? 'active' : ''">
                                <img class="d-block w-100" :src="image">
                            </div>
                        </div>
                        <a v-if="notePictures.length > 1" class="carousel-control-prev" href="#carouselImageIndicators" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a v-if="notePictures.length > 1" class="carousel-control-next" href="#carouselImageIndicators" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                </div>

                <button class="modal-close" @click="closeNotePictures"></button>
            </div>

            <operation-sounds></operation-sounds>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

const LoadSpinner = require('./containers/LoadSpinner.vue').default;
const OperationSounds = require('./containers/OperationSounds.vue').default;
const OperationResult = require('./containers/OperationResult.vue').default;

export default {
    props: ['action'],

    data() {
        return {
            loading: false,
            notePictures: [],
        }
    },

    computed: {
        ...mapGetters([
            'orderIsLoading',
            'orderId',
            'orderErrorMessage',
            'orderErrorType',
            'operationCompleted',
            'billingName',
            'changingBox',
            'scanFocus'
        ]),
        ...mapState({
            'orderNotes': state => state.notes.list,
        }),
        command: {
            get() {
                return this.$store.state.operation.command;
            },
            set(command) {
                this.setCommand(command);
            }
        },
        errorTypeClass() {
            return {
                'is-success': this.orderErrorType === 'OK',
                'is-warning': this.orderErrorType === 'WARNING',
                'is-danger': this.orderErrorType === 'ERROR',
            }
        }
    },

    created() {
        this.setOrder(this.$route.params.orderId);
        this.loadOrderNotes();
    },

    methods: {
        ...mapActions([
            'setOrder',
            'setCommand',
            'resetCommand',
            'confirmReadNote',
            'loadOrderNotes',
        ]),

        async submitScan() {
            this.loading = true;
            try {
                await this.$store.dispatch(this.action);
                this.resetCommand();
                this.loading = false;
            } catch (ex) {
                this.resetCommand();
                this.loading = false;
            }
        },

        async confirmNoteRead(note) {
            if (!note.read_at) {
                this.confirmReadNote(note.id);
                this.resetCommand();
            }
        },

        enableFocus() {
            this.$store.dispatch('enableFocus');
        },

        showNotePictures(orderNote) {
            this.$store.commit('UPDATE_SAW_PHOTOS', orderNote.id);
            this.notePictures = orderNote.pictures;
        },

        closeNotePictures() {
            this.notePictures = [];
        },
    },

    components: {
        LoadSpinner,
        OperationResult,
        OperationSounds,
    },

    watch: {
        operationCompleted(newVal, preVal) {
            if (newVal) {
                this.$router.go(-1);
            }
        },
        orderIsLoading() {
            this.resetCommand();
        },
        '$store.state.operation.scanFocus': {
            deep: true,
            handler() {
                if (!this.orderIsLoading && !this.orderErrorMessage && this.orderId) {
                    this.$nextTick(() => {
                        this.$refs.commandInput.focus();
                    })
                }
            }
        }
    }
}
</script>

<style scoped>
.section {
    padding-top: 1rem;
}

.header-operation {
    margin-bottom: 0px !important;
}

.column {
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}

.notification {
    padding: 0.4rem 1rem 0.4rem .4rem;
}
</style>
