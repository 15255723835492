<template>
    <div>
        <div class="section header-buttons">
            <div class="container">
                <div class="columns">
                    <button type="button"
                            class="button is-success"
                            :class="planning ? 'is-loading' : ''"
                            :disabled="selectedOrders.length === 0"
                            @click="planOrders"
                    >Esegui batch
                    </button>
                    <span class="selected-pieces">
                        <span v-if="selectedPieces > 0">
                            Pezzi selezionati: <b>{{ selectedPieces }}</b>
                        </span>
                        <span v-else>&nbsp;</span>
                    </span>
                    <button type="button" class="button is-warning" :class="planning ? 'is-loading' : ''"
                            @click="planByPieces">Pianificazione automatica
                    </button>
                </div>
            </div>
        </div>

        <operation tab-type="Plan" :have-batch="true" :row-function="planOrder" :delete-function="deleteOrder">
            <div class="columns">
                <div class="column is-1">
                    <button type="button"
                            class="button is-success"
                            :class="planning ? 'is-loading' : ''"
                            :disabled="selectedOrders.length === 0"
                            @click="planOrders"
                    >Esegui batch
                    </button>
                </div>
            </div>
        </operation>
    </div>
</template>

<script>
const { TO_PLAN_TYPE } = require('../constants.js');
const { RESET_SEARCH } = require('../store/mutation-types.js');
const Operation = require('./Operation.vue').default;
const Orders = require('../models/Orders.js').default;
import { mapActions, mapState } from 'vuex';

export default {
    data() {
        return {
            planning: false,
            printBoxes: false,
            printArticles: false,
        }
    },
    computed: {
        ...mapState({
            selectedOrders: state => state.orders.selected,
            orders: state => state.orders.list,
        }),
        selectedPieces() {
            let rows = this.orders.filter(order => {
                return _.includes(this.selectedOrders, order.id);
            });

            return _.sumBy(rows, 'pieces');
        }
    },
    created() {
        this.$store.dispatch('setType', TO_PLAN_TYPE);
        this.loadOrders({
            page: this.$route.query.page,
            paginate: this.$route.query.paginate
        });
    },
    beforeRouteLeave(to, from, next) {
        this.$store.commit(RESET_SEARCH);
        next();
    },
    methods: {
        ...mapActions([
            'planOrders',
            'loadOrders',
            'setError'
        ]),

        async planOrders() {
            try {
                this.planning = true;
                await Orders.planOrders(this.selectedOrders, this.printBoxes, this.printArticles);
                this.planning = false;
                this.$store.dispatch('loadOrders', {
                    records: this.$route.query.records,
                    page: this.$route.query.page,
                });

            } catch (e) {
                this.setError(e);
            }
        },

        async planOrder(orderId) {
            await Orders.planOrders([orderId]);
            this.$store.dispatch('loadOrders', {
                records: this.$route.query.records,
                page: this.$route.query.page,
            });
        },

        async deleteOrder(orderId) {
            await Orders.delete(orderId);
        },
        async planByPieces() {
            try {
                this.planning = true;
                await Orders.planByPieces();
                this.planning = false;
                this.$store.dispatch('loadOrders', {
                    records: this.$route.query.records,
                    page: this.$route.query.page,
                });
            } catch (e) {
                this.setError(e);
                this.planning = false;
            }
        }
    },
    components: {
        operation: Operation,
    }
}
</script>

<style scoped>
.header-buttons {
    padding: 1rem 1.5rem;
    text-align: center;
}

.selected-pieces {
    float: left;
    min-width: 150px;
}

.columns {
    display: flex;
    justify-content: space-between;
    margin: 0;
}
</style>
