<template>
    <base-operation action="scanItem">
        <div class="control is-horizontal" slot="added">
            <div class="control-label">
                <label class="label">Scatole</label>
            </div>
            <div class="field has-addons">
                <div class="control" v-show="!changingBox">
                    <div class="select">
                        <select @input="handleSelect($event.target.value)" v-model="selectedBox.packageId">
                            <option v-for="(box, index) in boxes"
                                    :value="box.packageId">{{ boxTitle(index, box) }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="control">
                    <div v-if="!changingBox">
                        <button class="button is-info"
                                @click="changeBox"
                                :disabled="insertNewPackageDisabled"
                        >
                            Inserisci scatola
                        </button>
                    </div>
                    <div v-else>
                        <p class="control has-addons">
                            <span class="select">
                                <select v-model="newPackage">
                                    <option v-for="box in availablePackages" :value="box.id">{{ box.name }}</option>
                                </select>
                            </span>
                            <button class="button is-info" @click="insertBox" :disabled="!newPackage">
                                Conferma
                            </button>
                            <a class="delete" @click="changeBox"></a>
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns" slot="content">
            <div class="column">
                <nav class="panel">
                    <p class="panel-heading" style="min-height: 50px;">
                        <strong>Da lavorare</strong> {{ itemsToCheck.length }}
                        <span class="field is-pulled-right" @click="showProvidedPackinglist = !showProvidedPackinglist">
                            <a class="button is-small">
                                <span class="icon is-small">
                                  <i class="fa fa-list"></i>
                                </span>
                            </a>
                        </span>
                    </p>

                    <div v-for="item in groupedItemsToCheck()" class="panel-block">
                        <item-check-box
                            :item="item"
                        ></item-check-box>
                    </div>
                </nav>
                <div class="preview" v-if="showProvidedPackinglist">
                    <div class="arrow_box">
                        <nav class="panel">
                            <div class="panel-block box__packinglist" v-for="box in providedPackinglist">
                                <span>
                                {{ box.name }} - {{ box.current_weight.toFixed(2) }} Kg
                            </span>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>

            <div class="column">
                <package-list></package-list>
            </div>

        </div>
    </base-operation>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import _ from 'lodash';

const { TO_CHECK_TYPE } = require('../constants.js');

const CheckBox = require('./CheckBox').default;
const PackageList = require('./PackageList').default;
const PackageContent = require('./PackagesContent').default;
const SearchBar = require('./SearchBar').default;
const BaseOperation = require('./BaseOperation.vue').default;

export default {
    data() {
        return {
            scannedItem: null,
            scanResult: '',
            recapIsActive: false,
            newPackage: '',
            changeBoxOn: false,
            showProvidedPackinglist: false,
            loading: false,
            orderIsLoading: true
        }
    },

    computed: {
        ...mapGetters([
            'itemsToCheck',
            'boxes',
            'selectedBox',
            'availablePackages',
            'providedPackinglist',
            'scanInProgress',
            'checkOrderLoading',
            'changingBox'
        ]),

        ...mapState({
            carrierServiceId: state => state.check.carrierServiceId,
            orderToCheck: state => state.operation.orderId,
        }),

        itemsChecked() {
            let rows = _(this.itemsPacked)
                .map('rows').value();
            let sum = 0;
            _.each(rows, r => {
                sum += r.length;
            });
            return sum;
        },

        insertNewPackageDisabled() {
            return _.size(this.selectedBox.rows) === 0;
        }
    },
    mounted() {
        this.$store.dispatch('setType', { type: TO_CHECK_TYPE });
        this.loadCheckOrder();
    },

    methods: {
        ...mapActions([
            'resetCheckOrder',
        ]),

        boxTitle(index, box) {
            let title = 'Scatola ' + (index + 1) + ' - ' + box.type;

            if (box.weight <= 0) {
                title += ' *';
            }
            return title;
        },

        async loadCheckOrder() {
            await this.$store.dispatch('loadCheckOrder');
            this.$store.dispatch('loadAvailablePackages');
        },

        handleSelect(boxId) {
            this.$store.dispatch('loadBox', { id: boxId });
        },

        resetScanItem() {
            this.scannedItem = '';
        },

        groupedItemsToCheck() {
            let simple = this.itemsToCheck;
            let group = _.groupBy(simple, 'key');

            let items = [];

            _.keys(group).forEach(key => {
                let item = group[key][0];
                items.push(Object.assign({}, item, { qty: group[key].length }));
            });

            return items;
        },

        insertBox() {
            let box = _.find(this.availablePackages, { id: this.newPackage });

            this.$store.dispatch('insertBox', { packageTypeId: box.id, packageName: box.name });
            this.$store.dispatch('endChangeBox');
            this.newPackage = null;
        },

        resetAvailablePackages() {
            this.availablePackages = [];
        },

        abortChangeBox() {
            this.resetAvailablePackages();
        },

        changeBox() {
            if (this.changingBox) {
                this.$store.dispatch('endChangeBox');
            } else {
                this.$store.dispatch('startChangeBox');
            }
        }
    },
    components: {
        itemCheckBox: CheckBox,
        PackageList,
        PackageContent,
        searchBar: SearchBar,
        BaseOperation,
    },
}
</script>

<style>
.arrow_box {
    position: relative;
    background: #eeeeee;
    border: 2px solid #BDBDBD;
}

.arrow_box:after, .arrow_box:before {
    bottom: 100%;
    left: 80%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
}

.arrow_box:after {
    border-color: rgba(238, 238, 238, 0);
    border-bottom-color: #eeeeee;
    border-width: 30px;
    margin-left: -30px;
}

.arrow_box:before {
    border-color: rgba(189, 189, 189, 0);
    border-bottom-color: #BDBDBD;
    border-width: 33px;
    margin-left: -33px;
}

.preview {
    position: absolute;
    width: 19%;
    left: 31%;
}

.title__packinglist {
    padding: 0.1rem !important;
}

.box__packinglist {
    padding: 0.2rem !important;
}
</style>
