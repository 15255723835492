const {
    ORDER_LIST_REQUEST,
    ORDER_LIST_SUCCESS,
    LOAD_CARRIERS_SUCCESS,
    SET_SERVER_ERROR,
    SET_ORDERS_TYPE,
    TOGGLE_ORDER_TO_SELECTED,
    TOGGLE_SELECT_ALL,
    ORDER_LIST_FAILURE
} = require('../mutation-types.js');
const Orders = require('../../models/Orders').default;
const Item = require('../../models/Item').default;
import { rangeDots } from "../../helpers";

const state = {
    list: [],
    sort: {
        direction: undefined,
        key: undefined
    },
    type: {
        process: '',
        rowProcess: ''
    },
    currentPage: '',
    from: '',
    lastPage: '',
    perPage: '',
    to: '',
    total: '',
    selected: [],
    loading: false,
    paginator: [],
};


// getters
const getters = {
    list: state => state.list,
    sort: state => state.sort,
    ordersType: state => state.type.process,
    rowProcessType: state => state.type.rowProcess,
    paginatorList: state => state.paginator,
    currentPage: state => state.currentPage,
    lastPage: state => state.lastPage,
    processType: state => state.type.process,
};

// actions
const actions = {
    async loadOrders({ commit, state, getters }, query) {
        let customer = getters.selectedSearchCustomer;
        let carrier = getters.selectedSearchCarrier;
        let urgent = getters.searchUrgent;
        let text = getters.searchText;
        let sortDirection = state.sort.direction;
        let sortKey = state.sort.key;

        commit(ORDER_LIST_REQUEST);

        let type = state.type.process;
        query = { ...query, customer, carrier, urgent, text, type, sortDirection, sortKey };

        try {
            let { data } = await Orders.load(query);
            let { orders, carriers } = data;
            commit(ORDER_LIST_SUCCESS, orders);
            commit(LOAD_CARRIERS_SUCCESS, carriers);
        } catch (error) {
            commit(SET_SERVER_ERROR, error);
        }
    },

    planOrders({ commit, state, getters }) {
        let selectedSearchCustomer = getters.selectedSearchCustomer;
        let selectedSearchCarrier = getters.selectedSearchCarrier;
    },

    setType({ commit }, type) {
        commit(SET_ORDERS_TYPE, type);
    },

    createReturnOrder({ commit }, { itemId, reason, fulfilment_order_product_id }) {
        return new Promise((resolve, reject) => {
            Item.createReturnOrder(itemId, reason, fulfilment_order_product_id)
                .then(({ data }) => {
                    resolve(data);
                })
        })
    },

    toggleOrderToSelected({ commit }, orderId) {
        commit(TOGGLE_ORDER_TO_SELECTED, { orderId });
    },

    toggleSelectAll({ commit }, { selectAll }) {
        commit(TOGGLE_SELECT_ALL, { selectAll });
    },

    setSort({ commit }, { direction, key }) {
        commit('SET_SORT', { direction, key });
    }
};

// mutations
const mutations = {
    [ORDER_LIST_REQUEST](state) {
        state.loading = true;
        state.selected = [];
    },

    [ORDER_LIST_SUCCESS](state, orders) {
        state.list = orders.data;
        state.currentPage = orders.current_page;
        state.from = orders.from;
        state.lastPage = orders.last_page;
        state.perPage = orders.per_page;
        state.to = orders.to;
        state.total = orders.total;
        state.paginator = rangeDots(state.currentPage, state.lastPage);
        state.loading = false;
    },

    [SET_ORDERS_TYPE](state, { type, processId }) {
        state.type.process = type;
        state.type.rowProcess = processId;
    },

    [ORDER_LIST_FAILURE](state) {

    },

    [TOGGLE_ORDER_TO_SELECTED](state, { orderId }) {
        state.selected = _.xor(state.selected, [orderId])
    },

    [TOGGLE_SELECT_ALL](state, { selectAll }) {
        if (!selectAll) {
            let ordersId = _.chain(state.list)
                .filter({ unprocessable: false }).map('id').value();
            state.selected = _.union(state.selected, ordersId);
        } else {
            state.selected = [];
        }
    },

    ['SET_SORT'](state, { direction, key }) {
        state.sort.direction = direction;
        state.sort.key = key;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
