<template>
    <base-operation action="checkWrap">
        <div class="columns" slot="content">
            <div class="column">
                <nav class="panel">
                    <p class="panel-heading" style="min-height: 50px;">
                        <strong>Da lavorare</strong> {{ itemsToWrap.length }}
                    </p>

                    <div v-for="item in itemsToWrap" class="panel-block">
                        <item-check-box :item="item">
                            <div slot="additional-info">

                                <div class="columns" v-if="item.giftwrap_message">
                                    <div class="column">
                                        <strong>Messaggio Biglietto Regalo:</strong>
                                    </div>
                                    <div class="column">
                                        <span>{{ item.giftwrap_message }}</span>
                                    </div>
                                </div>

                                <template v-if="item.customization_text">
                                    <div class="columns">
                                        <div class="column">
                                            <strong>Testo Personalizzazione:</strong>
                                        </div>
                                        <div class="column">
                                        <span :style="{ fontFamily: item.customization_font }">{{
                                                item.customization_text
                                            }}</span>
                                        </div>
                                    </div>

                                    <div class="columns">
                                        <div class="column">
                                            <strong>Font Personalizzazione:</strong>
                                        </div>
                                        <div class="column">
                                        <span>
                                            {{ item.customization_font }}
                                        </span>
                                        </div>
                                    </div>
                                </template>


                                <p>
                                    Istruzioni
                                </p>

                                <div class="columns">
                                    <div class="column">
                                        <button
                                            class="button is-success"
                                            @click="confirmWrap(item, $event)">
                                            Check
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </item-check-box>
                    </div>
                </nav>
            </div>

            <div class="column">
                <nav class="panel">
                    <p class="panel-heading" style="min-height: 50px;">
                        <strong>Lavorati</strong> {{ itemsWrapped.length }}
                    </p>

                    <div v-for="item in itemsWrapped" class="panel-block">
                        <item-check-box
                            :item="item"
                            :undo="removeCustomization"
                        ></item-check-box>
                    </div>
                </nav>
            </div>

        </div>
    </base-operation>
</template>

<script>
const { TO_WRAP_TYPE } = require('../constants.js');

const BaseOperation = require('./BaseOperation.vue').default;
const CheckBox = require('./CheckBox').default;

import { mapActions, mapGetters } from 'vuex';

export default {
    mounted() {
        this.$store.dispatch('setType', { type: TO_WRAP_TYPE });
        this.loadWrapOrder();
    },

    computed: {
        ...mapGetters([
            'itemsToWrap',
            'itemsWrapped',
        ])
    },

    methods: {
        ...mapActions([
            'checkWrap',
            'removeWrap',
            'setCommand',
            'resetCommand'
        ]),

        loadWrapOrder() {
            this.$store.dispatch('loadWrapOrder');
        },

        confirmWrap(item, event) {
            this.$store.dispatch('setCommand', item.fulfilment_order_product_id);
            this.$store.dispatch('checkWrap', 'keyboard')
                .then(() => this.resetCommand())
                .catch(() => this.resetCommand());
        },

        removeCustomization(item) {
            this.$store.dispatch('removeWrap', item.fulfilment_order_product_id);
        }
    },

    components: {
        BaseOperation,
        itemCheckBox: CheckBox,
    }
}
</script>