<template>
    <div class="box">
        <label for="authCode" class="label">Codice</label>
        <p class="control">
            <input id="authCode" v-model="authCode"
                   ref="authCode"
                   v-on:keyup.enter="fastLogin"
                   class="input"
                   type="text"
                   required
                   :disabled="logginIn"
                   autocomplete="off"
            />
            <label v-if="loginError" class="login-error">Login errato</label>
        </p>
    </div>
</template>

<script>
import auth from '../../auth';
import {getCookie} from "../../router";

export default {
    data() {
        return {
            authCode: null,
            loginError: false,
            logginIn: false
        }
    },
    methods: {
        fastLogin() {
            this.logginIn = true;
            auth.fastLogin(this.authCode, loginSuccess => {
                if (loginSuccess) {
                    this.$store.dispatch('loadUser');
                    let lastTab = getCookie('path');
                    if (lastTab) this.$router.push(lastTab);
                    else this.$router.replace(this.$route.query.redirect || '/plan');
                } else {
                    this.logginIn = false;
                    this.loginError = true;
                    this.authCode = null;
                }
            });
        }
    },
    mounted() {
        this.$refs.authCode.focus();
    }
}
</script>

<style>
.login-error {
    color: red;
}
</style>