<template>
    <div>
        <app-header></app-header>
        <router-view></router-view>
    </div>
</template>

<script>
const Header = require('./Header.vue').default;
const User = require('../models/User').default;

import { getUserIP } from '../helpers';

export default {
    components: {
        appHeader: Header
    },

    created() {
        getUserIP(ip => {
            localStorage.ip = ip;

            this.$store.dispatch('loadUser');

            User.createWorkstation(localStorage.getItem('ip'));
        });
    }
}
</script>