<template>
    <div class="hero-head is-fixed-top">
        <div style="text-align: center">{{ userFullName }}</div>
        <div class="container">
            <nav class="navbar has-shadow">
                <div class="navbar-menu">
                    <router-link class="navbar-item is-tab" to="/plan" exact @click.native="hideManualShipment">
                        <i class="fa fa-list"></i>&nbsp;Pianificazione
                        <div class="badge" :class="{ done: ordersToPlan == 0,  undone: ordersToPlan > 0}">
                            {{ ordersToPlan }}
                        </div>
                    </router-link>

                    <div class="navbar-item is-tab ">
                        <i class="fa fa-archive"></i>&nbsp;Picking
                        <div class="badge" :class="{ done: ordersToPick == 0, undone: ordersToPick > 0 }">
                            {{ ordersToPick }}
                        </div>
                    </div>
                    <router-link class="navbar-item is-tab" to="/check" @click.native="hideManualShipment">
                        <i class="fa fa-cogs"></i>&nbsp;Check
                        <div class="badge" :class="{ done: ordersToCheck == 0, undone: ordersToCheck > 0 }">
                            {{ ordersToCheck }}
                        </div>
                    </router-link>

                    <router-link class="navbar-item is-tab" to="/customization" @click.native="hideManualShipment">
                        <i class="fa fa-pencil"></i>&nbsp; Personalizzazione
                        <div class="badge" :class="{ done: ordersToCustomize == 0, undone: ordersToCustomize > 0 }">
                            {{ ordersToCustomize }}
                        </div>
                    </router-link>

                    <router-link class="navbar-item is-tab" to="/gift-wrap" @click.native="hideManualShipment">
                        <i class="fa fa-gift"></i>&nbsp;Regalo
                        <div class="badge" :class="{ done: ordersToWrap == 0, undone: ordersToWrap > 0 }">
                            {{ ordersToWrap }}
                        </div>
                    </router-link>

                    <router-link class="navbar-item is-tab" to="/closing" @click.native="hideManualShipment">
                        <i class="fa fa-truck"></i>&nbsp;Chiusura
                        <div class="badge" :class="{ done: ordersToClose == 0, undone: ordersToClose > 0 }">
                            {{ ordersToClose }}
                        </div>
                    </router-link>

                    <router-link class="navbar-item is-tab" to="/print" @click.native="hideManualShipment">
                        <i class="fa fa-print"></i>&nbsp;Ristampa
                    </router-link>

                    <div class="navbar-item is-pulled-right" :class="{ 'is-active': toggleActive }">
                        <div class="field is-grouped">
                            <p class="control">
                                <a class="button is-primary" @click="logout">
                                    <span class="icon">
                                        <i class="fa fa-sign-out"></i>
                                    </span>
                                    <span>Esci</span>
                                </a>
                            </p>
                        </div>
                    </div>

                </div>

                <span id="navbar-burger" class="navbar-burger" @click="toggleMenu">
                    <span></span>
                    <span></span>
                    <span></span>
                </span>
            </nav>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import _ from 'lodash';
import { HIDE_MANUAL_SHIPMENT } from '../store/mutation-types'

import auth from '../auth';

export default {
    data() {
        return {
            toggleActive: false,
            pusher: null,
        }
    },
    mounted() {
        this.$store.dispatch('getOrdersStat');

        if (process.env.NODE_ENV == 'production') {
            this.pusher = new Pusher('1c1234f6309c798febf2', {
                cluster: 'eu',
                encrypted: true
            });

            let channel = this.pusher.subscribe('fulfilment-process-updated');
            channel.bind('App\\Events\\FulfilmentProcessUpdated', ({ data }) => {
                this.updateOrdersStat(data);
            });
        }
    },

    computed: {
        ...mapGetters([
            'userFullName',
            'userId',
            'ordersToPlan',
            'ordersToPick',
            'ordersToCheck',
            'ordersToCustomize',
            'ordersToWrap',
            'ordersToClose',
            'doneProcesses',
            'undoneProcesses'
        ]),
        isSuperUser() {
            return _.includes([29], this.userId);
        }
    },

    methods: {
        ...mapActions([
            'updateOrdersStat',
            'loadOrders'
        ]),
        logout() {
            auth.logout(() => this.$router.replace(this.$route.query.redirect || '/login'));
        },
        toggleMenu() {
            this.toggleActive = !this.toggleActive;
        },
        authorization(auth) {
            return true;
        },
        hideManualShipment() {
            this.$store.commit(HIDE_MANUAL_SHIPMENT);
        }
    }
}
</script>
