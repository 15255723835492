<template>
    <div class="manual-shipment notification is-danger has-text-centered" v-if="message">
        <span>{{ message }}</span>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    computed: {
        ...mapState({
            message: state => state.manualShipment.message,
        }),
    }
}
</script>

<style>
.manual-shipment {
    margin: 1rem 0;
}

.manual-shipment span {
    font-weight: bold;
}
</style>