import axios from 'axios';

class Item {
    static getImages(itemId) {
        return axios.get(`fulfilment/item-images/${itemId}`);
    }

    static createReturnOrder(itemId, reason, fulfilment_order_product_id) {
        return axios.post(`/fulfilment/create-return-order`, { itemId, reason, fulfilment_order_product_id });
    }
}

export default Item;
