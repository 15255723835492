<template>
    <div id="loginScreen" class="columns is-vcentered">
        <div class="column is-4 is-offset-4">
            <h1 class="title">
                TL Warehouse Interface
            </h1>
            <a v-on:click="switchLoginInterface">Cambia metodo login</a>
            <component :is="loginComponent"></component>
        </div>
    </div>
</template>

<script>
const FastLogin = require('./FastLogin.vue').default;
const StandardLogin = require('./StandardLogin.vue').default;

export default {
    data() {
        return {
            loginComponent: 'fast-login'
        }
    },
    methods: {
        switchLoginInterface() {
            if (this.loginComponent === 'fast-login') {
                this.loginComponent = 'standard-login';
            } else {
                this.loginComponent = 'fast-login';
            }
        },
        canFastLogin(cb) {
            return true;
        }
    },
    components: {
        FastLogin,
        StandardLogin
    },
    mounted() {
        delete localStorage.token;
        delete localStorage.ip;
    }
}
</script>

<style>
#loginScreen {
    margin-top: 6%;
}
</style>
