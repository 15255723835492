import axios from 'axios';

class Packages {
    static all(carrierServiceId, then) {
        let url = carrierServiceId ? `packages/${carrierServiceId}` : 'packages';
        return axios.get(url).then(({ data }) => then(data));
    }

    static updateWeight(packageId, newWeight, orderId) {
        let data = {
            packageId,
            newWeight,
            orderId
        };

        return axios.post('fulfilment/update-package-weight', data);
    }

    static removeItem(fulfilmentOrderProductId) {
        return axios.post('fulfilment/remove-item', { fulfilmentOrderProductId })
    }
}

export default Packages;