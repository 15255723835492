<template>
    <div class="section">
        <div class="container">
            <form @submit.prevent="searchPrint">
                <div class="columns">
                    <!-- /.Report selection -->
                    <div class="column">
                        <label class="label">Tipologia</label>
                        <p class="control">
                    <span class="select">
                        <select v-model="selectedReport">
                            <option value="all">Tutti</option>
                            <option v-for="report in reports" :value="report.name">{{ report.trans }}</option>
                        </select>
                    </span>
                        </p>
                    </div>
                    <!-- /.End Report selection -->

                    <!-- /.Free Text Search -->
                    <div class="column is-2">
                        <label class="label">Cerca</label>
                        <div class="field has-addons">
                            <p class="control">
                                <input type="text" class="input" v-model="text">
                            </p>
                            <p class="control">
                                <button type="submit" class="button is-info">Invia</button>
                            </p>
                        </div>
                    </div>
                    <!-- /.Free Text Search -->
                </div>
            </form>

            <div v-if="loading">
                <load-spinner></load-spinner>
            </div>
            <div v-else>
                <table class="table">
                    <thead>
                    <tr>
                        <th>Tipo</th>
                        <th>Titolo</th>
                        <th>Stampante</th>
                        <th>Data di Stampa</th>
                        <th>&nbsp;</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="queue in printQueue.data">
                        <td>
                            {{ queue.type }}
                        </td>
                        <td>
                            {{ queue.title }}
                        </td>
                        <td>
                            <span class="select is-small">
                                <select v-model="queue.printer">
                                    <option v-for="printType in queue.enabled_printers"
                                            :value="printType">{{ printType }}</option>
                                </select>
                            </span>
                        </td>
                        <td>
                            {{ formatDate(queue.created_at) }}
                        </td>
                        <td>
                            <button class="button is-success is-small p-1"
                                    @click="reprint(queue._id, queue.printer, queue.title)">
                                Ristampa
                            </button>
                            <button v-if="queue.printer_type !== 'Zebra'" class="button is-info is-small p-1"
                                    @click="viewReport(queue)">
                                Visualizza
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <nav class="pagination is-centered">
                    <router-link :to="paginationUrl(currentPage - 1)" class="pagination-previous"
                                 :class="{ 'is-disabled': currentPage === 1 }">Precedente
                    </router-link>
                    <ul class="pagination-list">
                        <li v-for="range in rangedDots">
                            <span v-if="range == '...'" class="pagination-ellipsis">&hellip;</span>
                            <router-link v-else :to="paginationUrl(range)" class="pagination-link"
                                         :class="{ 'is-current': range === currentPage }">{{ range }}
                            </router-link>
                        </li>
                    </ul>
                    <router-link :to="paginationUrl(currentPage + 1)" class="pagination-next"
                                 :class="{ 'is-disabled': currentPage === lastPage || lastPage === 0 }">Successiva
                    </router-link>
                </nav>

            </div>
        </div>
        <div class="modal" :class="showFile ? 'is-active' : ''">
            <div class="modal-background" @click="showFile = !showFile"></div>
            <div class="modal-content">
                <div>
                    <button id="prev">Previous</button>
                    <button id="next">Next</button>
                    &nbsp; &nbsp;
                    <span>Page: <span id="page_num"></span> / <span id="page_count"></span></span>
                </div>
                <div>
                    <canvas id="the-canvas"></canvas>
                </div>
            </div>
            <button class="modal-close" @click="showFile = !showFile"></button>
        </div>


    </div>
</template>

<script>
const Print = require('../../models/Print.js').default;
const LoadSpinner = require('../containers/LoadSpinner.vue').default;

const PDFJS = require("pdfjs-dist/webpack");
const { rangeDots } = require('../../helpers');
import moment from 'moment';

export default {
    data() {
        return {
            reports: [],
            printQueue: {
                data: [],
                current_page: 0,
                from: 0,
                to: 0,
                last_page: 0,
                per_page: 0,
                total: 0
            },
            selectedReport: 'all',
            text: '',
            loading: false,
            showFile: false,
            rangedDots: []
        }
    },

    computed: {
        currentPage() {
            return this.printQueue.current_page;
        },
        lastPage() {
            return this.printQueue.last_page;
        },
        perPage() {
            return this.printQueue.per_page;
        }
    },

    mounted() {
        this.getReports();
        this.getPrintQueue();
    },

    methods: {
        paginationUrl(page) {
            return {
                name: 'print',
                query: {
                    page
                }
            }
        },

        async getReports() {
            let { data } = await Print.getReports();
            this.reports = data;
        },

        async getPrintQueue() {
            this.loading = true;
            let { data } = await Print.getPrintQueue({
                type: this.selectedReport,
                q: this.text,
                page: this.$route.query.page
            });

            this.printQueue = data;
            this.rangedDots = rangeDots(this.currentPage, this.lastPage);

            this.loading = false;
        },

        searchPrint() {
            this.$router.push({
                name: 'print', query: {
                    type: this.selectedReport,
                    q: this.text
                }
            });
        },

        formatDate(date) {
            return moment(date).format('DD/MM/YYYY H:mm:ss');
        },

        reprint(id, printer, title) {
            let r = confirm('Confermi di voler ristampare ' + title + ' sulla stampante ' + printer + '?')
            if (r) {
                Print.reprintDocument(id, printer);
            }
        },

        async viewReport(queue) {
            let response = null;
            if (queue.printer_type.toLowerCase() === 'zebra') {
                response = await Print.convertZPLToImage(queue._id);
            } else {
                response = await Print.getFile(queue._id);
            }

            let { data } = response;

            let pdfData = atob(data);
            let loadingTask = PDFJS.getDocument({ data: pdfData });

            let vm = this;
            loadingTask.promise.then(function (pdf) {

                // Fetch the first page
                let pageNumber = 1;
                pdf.getPage(pageNumber).then(function (page) {
                    let scale = 1.2;
                    let viewport = page.getViewport({ scale: scale });
                    // Prepare canvas using PDF page dimensions
                    let canvas = document.getElementById('the-canvas');
                    let context = canvas.getContext('2d');
                    canvas.height = viewport.height;
                    canvas.width = viewport.width;

                    // Render PDF page into canvas context
                    let renderContext = {
                        canvasContext: context,
                        viewport: viewport
                    };
                    let renderTask = page.render(renderContext);
                    renderTask.promise.then(function () {
                        vm.showFile = true;
                    });
                });
            }, function (reason) {
                // PDF loading error
                console.error(reason);
            });
        },
    },

    watch: {
        '$route': 'getPrintQueue'
    },

    components: {
        LoadSpinner
    }
}
</script>
