export const UNKNOWN_ITEM_EXCEPTION = 'The selected item id is invalid.';
export const ORDER_ALREADY_CHECKED_EXCEPTION = 'Order already checked';
export const ORDER_NOT_FOUND_EXCEPTION = 'Order not found';
export const CLOSING_ORDER_PENDING = 'Pending order';
export const CLOSING_ORDER_CLOSED = 'Closed order';
export const OK = 'OK';
export const SCAN_ITEM_UNEXPECTED = 'UNEXPECTED';
export const ENDPROCESS = 'ENDPROCESS';
export const ENDPROCESS_SUCCESS = 'ENDPROCESS_SUCCESS';

export const ROW_TYPE_COMMON = 'COMMON';
export const ROW_TYPE_ERROR = 'ERROR';

export const DONE_STATUS = 'done';
export const UNDONE_STATUS = 'undone';
export const ERROR_STATUS = 'error';

export const EMPTY_BOX = 'EMPTY_BOX';

export const FULFILMENT_ORDER_PENDING_STATUS = 1;

export const TO_PLAN_TYPE = { type: 6, processId: 1 };
export const TO_CHECK_TYPE = { type: 8, processId: 2 };
export const TO_CUSTOMIZE_TYPE = { type: 9, processId: 3 };
export const TO_WRAP_TYPE = { type: 10, processId: 5 };
export const TO_CLOSE_TYPE = { type: 7, processId: null };

export const MAX_QUANTITY_FAST_SCAN = 500;
export const TAB_REFRESH = 5 * 60 * 1000;
export const STATISTICS_REFRESH = 60 * 1000;