<template>
    <div class="section">
        <div class="container">
            <div>
                <search-bar></search-bar>
                <manual-shipment></manual-shipment>

                <order-list :have-batch="haveBatch" :row-function="rowFunction" :delete-function="deleteFunction"
                            :enable-print="enablePrint">
                    <slot></slot>
                </order-list>
                <nav class="pagination is-centered">
                    <router-link :to="paginationUrl(currentPage - 1)" class="pagination-previous"
                                 :class="{ 'is-disabled': currentPage === 1 }">Precedente
                    </router-link>
                    <ul class="pagination-list">
                        <li v-for="page in paginatorList">
                            <span v-if="page == '...'" class="pagination-ellipsis">&hellip;</span>
                            <router-link v-else :to="paginationUrl(page)" class="pagination-link"
                                         :class="{ 'is-current': page === currentPage }">{{ page }}
                            </router-link>
                        </li>
                    </ul>
                    <router-link :to="paginationUrl(currentPage + 1)" class="pagination-next"
                                 :class="{ 'is-disabled': currentPage === lastPage || lastPage === 0 }">Successiva
                    </router-link>
                    <div class="field" style="order: 4; margin-left: 1rem">
                        <div class="control">
                            <div class="select">
                                <select v-model="paginate" @change="loadList">
                                    <option v-for="perPage in perPages" :value="perPage">{{ perPage }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </div>
    </div>
</template>

<script>
const SearchBar = require('./SearchBar').default;
const OrderList = require('./OrdersList').default;
const ManualShipment = require('./containers/ManualShipment').default;
import { TAB_REFRESH } from '../constants';

import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    props: ['haveBatch', 'rowFunction', 'deleteFunction', 'enablePrint'],
    data() {
        return {
            perPages: [30, 50, 75, 100],
            paginate: 50,
            timer: ''
        }
    },
    computed: {
        ...mapState({
            selectedOrders: state => state.orders.selected,
        }),
        ...mapGetters([
            'paginatorList',
            'currentPage',
            'lastPage'
        ])
    },

    created() {
        this.resetOperation();
        this.timer = setInterval(() => {
            this.loadList({
                page: this.$route.query.page,
                paginate: this.$route.query.paginate
            });
        }, TAB_REFRESH);
    },
    beforeDestroy() {
        window.clearInterval(this.timer);
    },

    methods: {
        ...mapActions([
            'resetOperation',
            'loadOrders'
        ]),

        paginationUrl(page) {
            return {
                query: {
                    page
                }
            }
        },

        loadList() {
            this.loadOrders({
                page: this.$route.query.page,
                paginate: this.paginate
            });
        },
    },

    watch: {
        '$route': 'loadList' // Serve per il paginator
    },

    components: {
        searchBar: SearchBar,
        orderList: OrderList,
        manualShipment: ManualShipment,
    }
}
</script>

<style>
.title {
    display: flex;
    justify-content: space-between;
}

.pagination {
    margin-top: 1rem;
}
</style>
