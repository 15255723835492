<template>
    <div>
        <span>
        <slot></slot>
            <span class="sortable-arrows">
                <i class="fa fa-menzu ion-arrow-up-b pointer" @click="sortColumn('asc')"
                   :class="{ 'active-sort': sort.key === columnKey && sort.direction === 'asc'}"></i>
                <i class="fa fa-menzu ion-arrow-down-b pointer" @click="sortColumn('desc')"
                   :class="{ 'active-sort': sort.key === columnKey && sort.direction === 'desc'}"></i>
            </span>
        </span>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    props: ['columnKey'],
    computed: {
        ...mapGetters([
            'sort'
        ]),
    },
    methods: {
        ...mapActions([
            'loadOrders',
            'setSort'
        ]),

        sortColumn(direction) {
            this.setSort({
                direction: direction,
                key: this.columnKey
            });
            this.loadOrders();
        }
    }
}
</script>

<style>
.active-sort {
    color: lightgreen !important;
}
</style>