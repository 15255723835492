import axios from "axios";

export default {
    ipCheck(cb) {
        axios.get('ip-check').then(response => {
            if (cb) cb(true);
        }).catch(error => {
            if (cb) cb(false);
        });
    },

    fastLogin(authCode, cb) {
        axios.post('fast-login', {
            authCode
        }).then(response => {
            localStorage.token = response.data.token;
            if (cb) cb(response.data);
        }).catch(error => {
            if (cb) cb(false);
        })
    },

    fastLoginPromise(authCode) {
        return new Promise((resolve, reject) => {
            this.fastLogin(authCode, successLogin => {
                if (successLogin) {
                    resolve();
                } else {
                    reject();
                }
            });
        });
    },

    login(email, password, cb) {
        cb = arguments[arguments.length - 1];
        if (localStorage.token) {
            if (cb) cb(true);
            return;
        }

        axios.post('login', {
            email,
            password
        }).then(response => {
            if (response.status === 200) {
                localStorage.token = response.data.token;
                if (cb) cb(true);
            } else {
                if (cb) cb(false);
            }
        }).catch(error => {
            if (cb) cb(false);
        });
    },

    getToken() {
        return localStorage.token;
    },

    refreshToken() {
        if (this.loggedIn()) {
            return axios.get('refresh').then(response => {
                localStorage.token = response.data.newToken;
            }).catch(error => {
                delete localStorage.token;
            });
        }

        return Promise.resolve();
    },

    logout(cb) {
        delete localStorage.token;
        delete localStorage.ip;
        if (cb) cb();
    },

    loggedIn() {
        return !!localStorage.token;
    }
}