<template>
    <base-operation action="checkCustomization">
        <div class="columns" slot="content">
            <div class="column">
                <nav class="panel">
                    <p class="panel-heading" style="min-height: 50px;">
                        <strong>Da lavorare</strong> {{ itemsToCustomize.length }}
                    </p>

                    <div v-for="item in itemsToCustomize" class="panel-block">
                        <item-check-box :item="item">
                            <div slot="additional-info">

                                <div class="columns">
                                    <div class="column">
                                        <strong>Testo:</strong>
                                    </div>
                                    <div class="column">
                                        <span :style="{ fontFamily: item.customization_font }">{{
                                                item.customization_text
                                            }}</span>
                                    </div>
                                </div>

                                <div class="columns">
                                    <div class="column">
                                        <strong>Font:</strong>
                                    </div>
                                    <div class="column">
                                        <span>
                                            {{ item.customization_font }}
                                        </span>
                                    </div>
                                </div>

                                <p>
                                    Istruzioni
                                </p>

                                <div class="columns">
                                    <div class="column">
                                        <div class="tooltip_button">
                                            <transition name="fade">
                                            <span class="tooltiptext"
                                                  v-if="textCopied">Copiato!</span>
                                            </transition>
                                            <button class="button is-info" @click="copyCustomizationText(item, $event)">
                                                Copia
                                            </button>
                                        </div>
                                    </div>

                                    <div class="column">
                                        <button
                                            class="button is-success"
                                            @click="confirmCustomization(item, $event)">
                                            Check
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </item-check-box>
                    </div>
                </nav>
            </div>

            <div class="column">
                <nav class="panel">
                    <p class="panel-heading" style="min-height: 50px;">
                        <strong>Lavorati</strong> {{ itemsCustomized.length }}
                    </p>

                    <div v-for="item in itemsCustomized" class="panel-block">
                        <item-check-box
                            :item="item"
                            :undo="removeCustomization"
                        ></item-check-box>
                    </div>
                </nav>
            </div>
            <input
                type="text"
                v-model="copiedCustomization"
                ref="custInput"
                v-show="copiedCustomization"
            />
        </div>
    </base-operation>
</template>

<script>
const { TO_CUSTOMIZE_TYPE } = require('../constants.js');

const BaseOperation = require('./BaseOperation.vue').default;
const CheckBox = require('./CheckBox').default;

import { mapActions, mapGetters } from 'vuex';

export default {
    data() {
        return {
            copiedCustomization: '',
            textCopied: false,
        }
    },
    mounted() {
        this.$store.dispatch('setType', { type: TO_CUSTOMIZE_TYPE });
        this.loadCustomizeOrder();
    },

    computed: {
        ...mapGetters([
            'itemsToCustomize',
            'itemsCustomized',
        ])
    },

    methods: {
        ...mapActions([
            'checkCustomization',
            'removeCustomization',
            'setCommand',
            'resetCommand'
        ]),

        loadCustomizeOrder() {
            this.$store.dispatch('loadCustomizeOrder');
        },

        confirmCustomization(item, event) {
            event.target.disabled = true;
            this.$store.dispatch('setCommand', item.fulfilment_order_product_id);
            this.$store.dispatch('checkCustomization', 'keyboard')
                .then(() => {
                    this.resetCommand();
                    event.target.disabled = false;
                })
                .catch(() => this.resetCommand());
        },

        removeCustomization(item) {
            this.$store.dispatch('removeCustomization', item.fulfilment_order_product_id);
        },

        copyCustomizationText(item) {
            this.copiedCustomization = item.customization_text;

            this.$nextTick(() => {
                let custInput = this.$refs.custInput;
                custInput.select();
                document.execCommand("copy");

                this.copiedCustomization = null;
                this.textCopied = true;

                setTimeout(() => {
                    this.textCopied = false;
                }, 2000);
            });
        }
    },

    components: {
        BaseOperation,
        itemCheckBox: CheckBox
    }
}
</script>

<style>
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s
}

.fade-enter, .fade-leave-to {
    opacity: 0
}
</style>