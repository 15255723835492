const { ORDERS_STATISTICS_SUCCESS } = require('../mutation-types.js');
const Orders = require('../../models/Orders').default;

const state = {
    orders: {
        toPlan: 0,
        toPick: 0,
        toCheck: 0,
        toCustomize: 0,
        toWrap: 0,
        toClose: 0,
        doneProcesses: 0,
        undoneProcesses: 0
    }
};

const getters = {
    ordersToPlan: state => state.orders.toPlan,
    ordersToPick: state => state.orders.toPick,
    ordersToCheck: state => state.orders.toCheck,
    ordersToCustomize: state => state.orders.toCustomize,
    ordersToWrap: state => state.orders.toWrap,
    ordersToClose: state => state.orders.toClose,
    doneProcesses: state => state.orders.doneProcesses,
    undoneProcesses: state => state.orders.undoneProcesses,
};

const actions = {
    async getOrdersStat({ commit }) {
        let { data } = await Orders.loadStatistics();
        commit(ORDERS_STATISTICS_SUCCESS, data);
    },

    async updateOrdersStat({ commit }) {
        let { data } = await Orders.loadStatistics();
        commit(ORDERS_STATISTICS_SUCCESS, data);
    }
};

const mutations = {
    [ORDERS_STATISTICS_SUCCESS](state, {
        wait_for_plan,
        wait_for_pick,
        wait_for_check,
        wait_for_customization,
        wait_for_wrap,
        wait_for_close,
        doneProcesses,
        undoneProcesses
    }) {
        state.orders.toPlan = wait_for_plan ?? state.orders.toPlan;
        state.orders.toPick = wait_for_pick ?? state.orders.toPick;
        state.orders.toCheck = wait_for_check ?? state.orders.toCheck;
        state.orders.toCustomize = wait_for_customization ?? state.orders.toCustomize;
        state.orders.toWrap = wait_for_wrap ?? state.orders.toWrap;
        state.orders.toClose = wait_for_close ?? state.orders.toClose;
        state.orders.doneProcesses = Math.ceil(doneProcesses);
        state.orders.undoneProcesses = Math.ceil(undoneProcesses);
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};