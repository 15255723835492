const { SET_SERVER_ERROR, RESET_SERVER_ERROR } = require('../mutation-types.js');

const state = {
    error: null,
};

const getters = {
    serverError: state => state.error
};

const actions = {
    setError({ commit }, error) {
        commit(SET_SERVER_ERROR, error);
    },
    resetError({ commit }) {
        commit(RESET_SERVER_ERROR);
    }
};

const mutations = {
    [SET_SERVER_ERROR](state, { response }) {
        state.error = response.data;
    },

    [RESET_SERVER_ERROR](state) {
        state.error = null;
    }
};

export default {
    state,
    getters,
    actions,
    mutations,
}