<template>
    <div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

const { Howl } = require('howler');

export default {
    computed: {
        ...mapGetters([
            'operationSounds',
        ]),
    },

    methods: {
        soundPath(sound) {
            return `../static/${sound}`
        },
    },
    watch: {
        operationSounds(sounds) {
            createPlayer(sounds, 0, this.soundPath);
        }
    }
}

function createPlayer(sounds, current, p) {
    if (sounds && sounds.length === current) return;
    new Howl({
        src: [p(sounds[current])],
        autoplay: true,
        onend: () => {
            let next = current + 1;
            createPlayer(sounds, next, p);
        }
    })
}
</script>