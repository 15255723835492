<template>
    <nav class="panel">
        <div class="panel-heading">
            <div class="columns">
                <div class="column is-8">
                    <span class="panel-title">{{ panelTitle }}</span>
                    <input class="input is-small"
                           style="width: 17%"
                           type="text"
                           :disabled="updateWeightDisabled"
                           min="0.1"
                           v-model="weight"
                    />
                    <span class="panel-title"> kg</span>
                </div>

                <div class="column">
                    <button v-if="!changeBoxOn"
                            class="button is-info"
                            @click="changeBoxOn = !changeBoxOn">Cambia scatola
                    </button>
                    <div v-else>
                        <span class="select is-small">
                            <select v-model="newPackage" @change="changeBox">
                                <option v-for="box in availablePackages" :value="box.id">{{ box.name }}</option>
                            </select>
                        </span>

                        <a class="delete" @click="changeBoxOn = !changeBoxOn"></a>
                    </div>
                </div>
            </div>
        </div>

        <div v-for="item in groupedPackageContent()" class="panel-block">
            <item-check-box
                :item="item"
                :undo="removeItem"
                :readonly="readonly"
            ></item-check-box>
        </div>

    </nav>
</template>

<script>
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';

const { EMPTY_BOX, ROW_TYPE_ERROR } = require('../constants');
const { UPDATE_BOX_WEIGHT_SUCCESS } = require('../store/mutation-types');

const CheckBox = require('./CheckBox.vue').default;

export default {
    props: ['readonly'],
    data() {
        return {
            newPackage: '',
            copyWeight: '',
            changeBoxOn: false,
        }
    },
    created() {
        this.newPackage = this.selectedBox.id;
    },
    computed: {
        ...mapGetters([
            'packageContent',
            'availablePackages',
        ]),

        ...mapState({
            selectedBox: state => state.packinglist.selected,
            boxes: state => state.packinglist.boxes,
            carrierServiceId: state => state.check.carrierServiceId,
        }),

        panelTitle() {
            let that = this;
            let index = _.findIndex(this.boxes, v => v.packageId === that.selectedBox.packageId);

            index++;

            return 'Scatola ' + index + ' - ' + this.selectedBox.type + ', ' + this.selectedBox.rows.length + ' articoli';
        },

        weight: {
            get() {
                return this.selectedBox.weight;
            },
            set(newWeight) {
                this.$store.commit(UPDATE_BOX_WEIGHT_SUCCESS, { weight: newWeight });
                this.$store.dispatch('updatePackageWeight');
                this.$store.dispatch('disableFocus');
            }
        },

        updateWeightDisabled() {
         return false;
         // return (_.isString(this.selectedBox.packageId) && this.selectedBox.packageId.indexOf(EMPTY_BOX) >= 0);
        },
    },
    methods: {
        changeBox() {
            let newBox = this.availablePackages.find(p => p.id === this.newPackage);
            this.$store.dispatch('changeBox', { newBox })
                .then(() => {
                    this.changeBoxOn = false;
                });
        },

        removeItem(item) {
            this.$store.dispatch('removeFromPackinglist', { fulfilment_order_product_id: item.fulfilment_order_product_id });
        },

        groupedPackageContent() {
            let items = [];
            // Gli articoli in errore sempre in alto
            let error = _.filter(this.packageContent, x => x.type === ROW_TYPE_ERROR);

            error.forEach(x => items.push(x));

            let simple = _.filter(this.packageContent, x => x.type !== ROW_TYPE_ERROR);
            let group = _.groupBy(simple, 'key');

            _.keys(group).forEach(key => {
                let elements = group[key];

                // Per ogni raggruppamento, prendo la data massima, ovvero l'ultimo articolo letto
                let maxDate = _.chain(group[key]).map('updated_at').max().value();
                let maxDateIdx = _.findIndex(elements, i => {
                    return i.updated_at === maxDate;
                });

                let item = group[key][maxDateIdx];

                // Inserisco nell'array, l'elemento con data massima
                items.push(Object.assign({}, item, { qty: group[key].length }));
            });

            // Ordino ogni raggruppamento per data, in modo che il gruppo contenente l'ultimo articolo letto, sia sempre al primo posto
            return _.orderBy(items, 'updated_at', 'desc');
        },
    },
    components: {
        itemCheckBox: CheckBox
    },
}
</script>

<style scoped>
.panel-title {
    font-size: 0.8em;;
}
</style>