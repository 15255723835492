const {
    ORDER_LOADED_FAILURE,
    STATUS_INFO_SUCCESS,
    SET_PACKING_LIST,
    SET_CURRENT_PACKAGE,
    ORDER_LOADED_SUCCESS,
    CLOSE_INFO_SUCCESS,
    SHOW_MANUAL_SHIPMENT,
    END_PROCESS_SUCCESS,
    SET_OPERATION_SOUNDS,
    SET_OPERATION_RESULT,
    SET_SERVER_ERROR
} = require('../mutation-types.js');

const Orders = require('../../models/Orders').default;

import auth from '../../auth';
import { isLoginCode } from '../../helpers';
import router from '../../router.js';

const state = {
    packages: [],
    rows: [],
    order: ''
};

const getters = {
    orderToClose: state => state.order,
};

const actions = {
    async loadCloseOrder({ commit, getters }) {
        let orderId = getters.orderId;

        try {
            let { data } = await Orders.loadCloseOrder(orderId);
            let { status_info } = data;

            if (status_info === undefined) {
                let { response, description } = data;
                commit(ORDER_LOADED_FAILURE, { type: response, message: description });
                if (description == 'Contiene pacco regalo') {
                    router.push({
                        name: 'gift-wrapOrder',
                        params: {
                            'orderId': orderId
                        }
                    });
                }
            } else {
                commit(ORDER_LOADED_SUCCESS);
                commit(CLOSE_INFO_SUCCESS, data);
                let { sounds } = data;
                commit(SET_OPERATION_SOUNDS, { sounds });

                commit(STATUS_INFO_SUCCESS, { status_info: data.status_info });

                commit(SET_PACKING_LIST, data.status_info.packages);
                commit(SET_CURRENT_PACKAGE, undefined);
            }
        } catch (error) {
            commit(ORDER_LOADED_FAILURE, error.response.data);
        }
    },

    checkCloseOrder({ commit, getters, dispatch }, method) {
        return new Promise((resolve, reject) => {
            let command = getters.currentCommand;
            let orderId = getters.orderId;

            let data = {
                source: 'CLOSE',
                command,
                orderId,
                method,
            };

            if (isLoginCode(command)) {
                auth.fastLogin(command, loginSuccess => {
                    if (loginSuccess) {
                        dispatch('loadUser');
                        resolve();
                    } else {
                        auth.logout(() => next({ path: '/login' }));
                    }
                });
            } else {
                Orders.scan(data)
                    .then(resp => {
                        let { response, operation, sounds, description } = resp.data;
                        if (response === 'OK') {
                            switch (operation) {
                                case 'ENDPROCESS':
                                    commit(END_PROCESS_SUCCESS);

                                    break;
                            }
                        }

                        commit(SET_OPERATION_RESULT, { description, level: response });
                        commit(SET_OPERATION_SOUNDS, { sounds });
                        commit(SHOW_MANUAL_SHIPMENT, description);
                        resolve();
                    }).catch(error => {

                    commit(SET_SERVER_ERROR, error);
                    reject(error);
                });
            }
        })
    }
};

const mutations = {
    [CLOSE_INFO_SUCCESS](state, { status_info, order }) {
        state.order = order;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
}
