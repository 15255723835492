import axios from 'axios';

class Print {
    static printItemLabel(itemId) {
        return axios.post('fulfilment/print-item-label', { itemId });
    }

    static getReports() {
        return axios.get('fulfilment/reports');
    }

    static getPrintQueue(query) {
        return axios.get('fulfilment/print-queue', {
            params: query,
        });
    }

    static reprintDocument(id, printer) {
        let data = {
            id,
            printer
        };

        return axios.post('fulfilment/print-document', data);
    }

    static getFile(id) {
        return axios.get(`fulfilment/get-print-file/${id}`);
    }

    static convertZPLToImage(id) {
        return axios.get(`fulfilment/convert-zpl-to-image/${id}`);
    }

    static printOrder(orderId) {
        return axios.get(`fulfilment/${orderId}/print`);
    }
}

export default Print;