<template>
    <base-operation action="checkCloseOrder">
        <div class="control is-horizontal" slot="added">
            <div class="control-label">
                <label class="label">Scatole</label>
            </div>
            <div class="field has-addons">
                <div class="control">
                    <div class="select">
                        <select @input="handleSelect($event.target.value)" v-model="selectedBox.packageId">
                            <option v-for="(box, index) in boxes"
                                    :value="box.packageId">Scatola {{ (index + 1) }} - {{ box.type }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <div class="columns" slot="content">
            <div class="column">
                <div class="box">
                    <article class="media">
                        <div class="media-left">
                            <figure v-if="orderToClose.logo_url" class="image is-64x64">
                                <img :src="orderToClose.logo_url" alt="Image">
                            </figure>
                            <label v-else>
                                Consegna in sede
                            </label>
                        </div>
                        <div class="media-content">
                            <div class="content">
                                <p>
                                    <strong>{{ orderToClose.full_name }}</strong>
                                    <br>
                                    {{ orderToClose.country }}
                                </p>
                            </div>
                        </div>
                    </article>
                </div>

            </div>
            <div class="column">
                <package-list :readonly="true"></package-list>
            </div>
        </div>
    </base-operation>
</template>

<script>
const { TO_CLOSE_TYPE } = require('../constants.js');

const BaseOperation = require('./BaseOperation.vue').default;
const CheckBox = require('./CheckBox').default;
const PackageList = require('./PackageList').default;

import { mapActions, mapGetters } from 'vuex';

export default {
    mounted() {
        this.$store.dispatch('setType', { type: TO_CLOSE_TYPE });
        this.loadCloseOrder();
    },

    computed: {
        ...mapGetters([
            'orderToClose',
            'selectedBox',
            'boxes'
        ])
    },

    methods: {
        ...mapActions([
            'setCommand'
        ]),

        async loadCloseOrder() {
            await this.$store.dispatch('loadCloseOrder');
            this.$store.dispatch('loadAvailablePackages');
        },

        handleSelect(boxId) {
            this.$store.dispatch('loadBox', { id: boxId });
        },

        confirmCloseOrder() {
            this.setCommand('ENDPROCESS');
            this.$store.dispatch('checkCloseOrder', 'keyboard')
                .then(() => this.resetCommand())
                .catch(() => this.resetCommand());
        },
    },

    components: {
        BaseOperation,
        itemCheckBox: CheckBox,
        PackageList
    }
}
</script>