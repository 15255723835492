const {
    CHECK_REQUEST,
    ORDER_LOADED_FAILURE,
    STATUS_INFO_SUCCESS,
    SET_PACKING_LIST,
    SET_CURRENT_PACKAGE,
    ORDER_LOADED_SUCCESS,
    CHECK_SUCCESS,
    RESET_OPERATION_RESULT,
    START_SCAN,
    END_PROCESS_SUCCESS,
    RESET_QUANTITY,
    SET_OPERATION_SOUNDS,
    SCAN_ITEM_UNEXPECTED,
    ADD_IN_BOX_UNEXPECTED,
    SET_OPERATION_RESULT,
    END_SCAN,
    SET_SERVER_ERROR,
    RESET_CHECK_ORDER,
    UNDO_CHECK
} = require('../mutation-types.js');
const { UNKNOWN_ITEM_EXCEPTION, DONE_STATUS, UNDONE_STATUS } = require('../../constants.js');

const Orders = require('../../models/Orders').default;

import auth from '../../auth';
import router from '../../router.js';
import { isLoginCode } from '../../helpers';

const state = {
    carrierServiceId: null,
    billingName: null,
    status: '',
    rows: [],
    packinglist: [],
    loading: false
};

const getters = {
    itemsToCheck: state => _.filter(state.rows, { check: 'undone' }),
    orderRows: state => state.rows,
    carrierService: state => state.carrierServiceId,
    billingName: state => state.billingName,
    providedPackinglist: state => state.packinglist,
    checkOrderLoading: state => state.loading,
};

const actions = {
    async loadCheckOrder({ commit, getters }) {
        commit(CHECK_REQUEST);
        let orderId = getters.orderId;

        return new Promise((resolve, reject) => {
            Orders.loadCheckOrder(orderId, response => {

                let { status_info } = response;
                let { packages } = status_info;

                if (packages === undefined) {
                    let { description, response } = status_info;
                    commit(ORDER_LOADED_FAILURE, { type: response, message: description });
                } else {
                    commit(STATUS_INFO_SUCCESS, { status_info });
                    commit(SET_PACKING_LIST, packages);
                    commit(SET_CURRENT_PACKAGE, undefined);

                    commit(ORDER_LOADED_SUCCESS);
                    commit(CHECK_SUCCESS);
                }

                resolve();
            }).catch(error => {
                commit(ORDER_LOADED_FAILURE, error.response.data);
                reject()
            });
        });
    },

    scanItem({ commit, getters, dispatch }) {
        commit(RESET_OPERATION_RESULT);
        commit(START_SCAN);

        return new Promise((resolve, reject) => {
            let selectedBox = getters.selectedBox;
            let command = getters.currentCommand;
            let quantity = getters.currentQuantity;
            let orderId = getters.orderId;

            let data = {
                source: 'CHECK',
                command,
                quantity,
                currentPackage: selectedBox.packageId,
                packageTypeId: selectedBox.id,
                weight: selectedBox.weight,
                forceInsert: selectedBox.forceInsert,
                loadInThisBox: selectedBox.loadInThisBox,
                orderId
            };

            if (isLoginCode(command)) {
                auth.fastLogin(command, loginSuccess => {
                    if (loginSuccess) {
                        dispatch('loadUser');
                        resolve();
                    } else {
                        auth.logout(() => router.push('/login'));
                    }
                });
            } else {
                Orders.scan(data)
                    .then(resp => {
                        let { response, parameters, sounds, operation, description, status, isLast } = resp.data;

                        if (response === 'OK') {
                            switch (operation) {
                                case 'ENDPROCESS':
                                    commit(END_PROCESS_SUCCESS);
                                    break;
                                case 'ADD_ITEM':
                                    let { fulfilment_order_product_found, packing_list_id } = parameters;
                                    let { packages } = status;

                                    commit(STATUS_INFO_SUCCESS, { status_info: status });
                                    commit(SET_PACKING_LIST, packages);
                                    commit(SET_CURRENT_PACKAGE, packing_list_id);

                                    commit(RESET_QUANTITY);
                                    break;
                            }

                            commit(RESET_OPERATION_RESULT);
                            if (isLast === 1) {
                                commit(SET_OPERATION_SOUNDS, { sounds });
                                let data = {
                                    source: 'CHECK',
                                    command: 'ENDPROCESS',
                                    quantity: 1,
                                    currentPackage: selectedBox.packageId,
                                    packageTypeId: selectedBox.id,
                                    weight: selectedBox.weight,
                                    forceInsert: selectedBox.forceInsert,
                                    loadInThisBox: selectedBox.loadInThisBox,
                                    orderId
                                };
                                Orders.scan(data)
                                    .then(resp => {
                                        let {
                                            response,
                                            parameters,
                                            sounds,
                                            operation,
                                            description,
                                            status,
                                            isLast
                                        } = resp.data;

                                        if (response === 'OK') {
                                            commit(END_PROCESS_SUCCESS);
                                        }
                                    });
                            }
                        } else if (response === 'KO') {
                            switch (operation) {
                                case 'UNEXPECTED_ITEM':
                                    commit(SCAN_ITEM_UNEXPECTED, { result: parameters });
                                    let { packing_list_id, fulfilment_order_product_id } = parameters;
                                    commit(ADD_IN_BOX_UNEXPECTED, {
                                        packing_list_id,
                                        fulfilment_order_product_id
                                    });
                                    break;
                            }
                        }
                        commit(SET_OPERATION_RESULT, { description, level: response });
                        commit(SET_OPERATION_SOUNDS, { sounds });
                        commit(END_SCAN);

                        resolve(response);
                    })
                    .catch((error) => {
                        let message = error.response['data'];
                        if (_.has(message, 'itemId')) {
                            if (message['itemId'][0] === UNKNOWN_ITEM_EXCEPTION) {
                                reject(UNKNOWN_ITEM_EXCEPTION);
                            }
                        } else {
                            commit(SET_SERVER_ERROR, error);
                        }

                        commit(END_SCAN);
                        reject();
                    });
            }

        });
    },

    resetCheckOrder({ commit }) {
        commit(RESET_CHECK_ORDER);
    },
};

const mutations = {
    [STATUS_INFO_SUCCESS](state, { status_info }) {
        let { carrier_service_id, rows, packinglist, billing_name } = status_info;

        state.rows = rows;
        state.carrierServiceId = carrier_service_id;
        state.billingName = billing_name;
        state.packinglist = packinglist;
    },

    [SCAN_ITEM_UNEXPECTED](state, { result }) {
        state.rows.push(result);
    },

    [UNDO_CHECK](state, { fulfilment_order_product_id }) {
        let item = _.find(state.rows, { fulfilment_order_product_id });

        if (item.check === DONE_STATUS) {
            item.check = UNDONE_STATUS;
            item.packing_list_id = null;
        } else {
            let index = _.findIndex(state.rows, { fulfilment_order_product_id });
            state.rows.splice(index, 1);
        }
    },
    [RESET_CHECK_ORDER](state) {
        state.status = '';
        state.rows = [];
        state.packages = {
            box: [],
            selected: ''
        };
    },
    [CHECK_REQUEST](state) {
        state.loading = true;
    },
    [CHECK_SUCCESS](state) {
        state.loading = false;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
}
